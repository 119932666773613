/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import update from "immutability-helper";
import FacebookLogin from "react-facebook-login";
import { format } from "date-fns";
var base64 = require("base-64");
var dateFormat = require("dateformat");
import {
  projectTitle,
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  baseUrl,
  fbAppId,
  googleAppId,
  CountryTxt,
  stripeReference,
  stripeCurrency,
  stripeImage,
  stripeCompany,
  currencySymbol,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_STATIC_BLOCK,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_FORGET_PASSWORD,
  GET_CHANGEPASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
} from "../Helpers/SettingHelper";

import OrderdatetimeSlot from "./OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import {
  Login,
  Forgotpassword,
  Signup,
  Membersignin,
  Membersignup,
  Changepassword,
  Guestcheckout,
} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import Win from "../../common/images/win.png";
import warningImg from "../../common/images/warning.svg";
import warningplus from "../../common/images/18plus.png";

import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";

import deliveryImg from "../../common/images/green-scooter.png";
import deliveryWtImg from "../../common/images/delivery-w.svg";
import takeawayImg from "../../common/images/green-takeway.png";
import takeawayWtImg from "../../common/images/takeaway-w.svg";
import loupe from "../../common/images/search-black.svg";
import cartLike from "../../common/images/like.png";
import cartLikeActive from "../../common/images/like-active-header.png";
import threeline from "../../common/images/three-line.png";

import Cartsidebar from "./Cartsidebar";
import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob";
var Parser = require("html-react-parser");
var qs = require("qs");
class Header extends Component {
  constructor(props) {
    super(props);
    var loginuser =
      localStorage.getItem("loginuser") !== "" &&
      localStorage.getItem("loginuser") !== null &&
      typeof localStorage.getItem("loginuser") !== undefined &&
      typeof localStorage.getItem("loginuser") !== "undefined"
        ? base64.decode(localStorage.getItem("loginuser"))
        : "";
    var loginpassword =
      localStorage.getItem("loginpassword") !== "" &&
      localStorage.getItem("loginpassword") !== null &&
      typeof localStorage.getItem("loginpassword") !== undefined &&
      typeof localStorage.getItem("loginpassword") !== "undefined"
        ? base64.decode(localStorage.getItem("loginpassword"))
        : "";
    this.state = {
      seletedAvilablityId: "",
      seletedOutletId: "",
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      searchProResult: [],
      selectedProResult: [],
      orderHandled: "",
      orderDeliveryAddress: "",
      nextavail: "",
      logindata: "",
      deliveryInfo: [],
      pickupInfo: [],
      /* For Advanced Slot Start */
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      its_ascentis_memberlogin: "no",
      slotType: "0",
      /* For Advanced Slot End */
      member_lginactive: "sign-up",
      fields: {
        email: loginuser,
        password: loginpassword,
        rememberme: loginuser !== "" && loginpassword !== "" ? true : false,
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscheckout: {
        check_email: "",
        check_firstname: "",
        check_phone: "",
      },
      fieldsmembership: {
        firstname: "",
        email: "",
        reemail: "",
        password: "",
        rePassword: "",
        gender: "",
        mobilecountry: "",
        mobile: "",
        telephonecountry: "",
        telephone: "",
        address: "",
        postalcode: "",
        nationality: "",
        sendsms: "",
        sendemail: "",
        referrercode: "",
        captcha: "",
        terms: "",
        birthdate: "",
        email_already_exists: "no",
        mobile_already_exists: "no",
        completeReg: "N",
      },
      fieldsmembershiplogin: {
        mobile: "",
        password: "",
        mobilecountry: "",
        completeReg: "N",
      },
      registerMember: false,
      regmemberstatus: "initiating",
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
        completeReg: "N",
      },
      fieldschpassword: {
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
      enableOtp: false,
      menuData: [],
      openOutletTime: false,
      googlelogin: "No",
      trigerGlogin: false,
      fbstatus: "ok",
      staticblacks: [],
      instantorders: "",
      omise_payment_enable: 0,
      omise_log_id: "",
      omisecodeerror: "",
      omiseyearerror: "",
      omisemontherror: "",
      omisecardrror: "",
      omisenameerror: "",
      holdername: "",
      cardNumber: "",
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      cardImage: "",
      payment_ref_id: "",
      omise_tokken_response: "",
      omise_tokken_card_id: "",
      omise_tokken_id: "",
      member_reg_amount: "25.00",
      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
    };

    this.props.getGlobalSettings();
    this.props.getStaticBlock();
    this.props.getPickupOutlets();
    this.props.getAllOutlets(deliveryId);
    this.props.getSecondaryAddress();

    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
  }

  componentDidMount() {
    var currenturl = window.location.href;
    var currentThis = this;
    var substringtxt = "checkout";
    if (currenturl.includes(substringtxt) !== true) {
      const script = document.createElement("script");
      script.src = "https://cdn.omise.co/omise.js";
      script.async = true;
      document.body.appendChild(script);
    }

    $(document).click(function (e) {
      if ($(e.target).is(".menu li a span") && !$(e.target).is(".menu li a")) {
        $("#close_mobile_menu").trigger("click");
      }
    });

    $("body").on("click", ".open-onmore-popup", function (e) {
      e.preventDefault();
      var popuplink = $(this).attr("href");
      if (popuplink === "#membership-form-popup") {
        currentThis.setState({ member_lginactive: "sign-in" });
      }

      setTimeout(function () {
        $.magnificPopup.open({
          items: {
            src: popuplink,
          },
          type: "inline",
        });
      }, 200);
    });
    $("body").on("click", ".open-memeber-register", function (e) {
      e.preventDefault();
      setTimeout(function () {
        $.magnificPopup.open({
          items: {
            src: "#membership-form-popup",
          },
          type: "inline",
        });
      }, 200);
      currentThis.setState({ member_lginactive: "sign-up" });
    });
    $("body").on("click", ".open-memeber-login", function (e) {
      e.preventDefault();
      setTimeout(function () {
        $.magnificPopup.open({
          items: {
            src: "#membership-form-popup",
          },
          type: "inline",
        });
      }, 200);
      currentThis.setState({ member_lginactive: "sign-in" });
    });

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );

    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    if (this.props.match.path === "/sign-in") {
      if (!cookie.load("UserId")) {
        $.magnificPopup.open({
          items: {
            src: "#signup-popup",
          },
          type: "inline",
        });
      } else {
        this.props.history.push("/myaccount");
      }
    }
    this.props.getMenuData("cms");
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec").toggleClass("open");
        $(".hsearch_sec .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      });
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }
    /* Mobile header menu */
    $(".hcategory_trigger").click(function () {
      $(this).toggleClass("active");
      $(".hcategory_menu").toggleClass("open");
    });

    /* $(document).click(function (e) {
      if (!$(e.target).is(".hcategory_trigger")) {
        if ($(".hcategory_menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".hcategory_trigger").removeClass("active");
        }
      }
    }); */
    $(document).click(function (e) {
      if ($(e.target).is(".hcategory_submenu li a")) {
        $(this).toggleClass("active");
        $(".hcategory_menu").toggleClass("open");
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });
    this.getSearchProductList();
  }

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.homePageState !== undefined) {
      if (this.state.openOutletTime !== PropsDt.homePageState.openOutletTime) {
        this.setState(
          { openOutletTime: PropsDt.homePageState.openOutletTime },
          function () {
            if (PropsDt.homePageState.openOutletTime === true) {
              this.pickOutlet(this.state.pickupOutlets[0]);
              this.props.sateValChange("openOutletTime", false);
            }
          }
        );
      }
    }

    if (PropsDt.staticblack !== this.state.staticblacks) {
      var instantorders = "";
      /*  
      var homeTestmonial = "";
      var homeOurSpecialDishes = ""; */
      if (Object.keys(PropsDt.staticblack).length > 0) {
        PropsDt.staticblack.map((data) => {
          if (data.staticblocks_slug === "top-info") {
            instantorders = data.staticblocks_description;
            return "";
          }
        });
      }

      instantorders =
        instantorders !== "" && instantorders !== null
          ? Parser(instantorders)
          : instantorders;
      this.setState({
        instantorders: instantorders,
      });
    }

    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState(
        {
          pickupOutlets: PropsDt.outletslist,
          pickupOutletsList: PropsDt.outletslist,
        },
        function () {
          if (this.props.location.pathname === "/") {
            this.props.sateValChange("pickup_outlet", PropsDt.outletslist);
          }
        }
      );
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }

    if (this.state.googlestatus === "loading") {
      if (PropsDt.googlelogin !== undefined) {
        if (PropsDt.googlelogin.length > 0) {
          this.setState({ googlestatus: "ok" }, function () {
            this.doLogin(PropsDt.googlelogin[0]);
          });
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        this.showMessage(PropsDt.registration[0]);
        var formPay_load = this.state.fieldssignup;
        this.setState({
          regstatus: "ok",
          enableOtp: false,
          fieldssignup: {
            firstname: "",
            email: "",
            password: "",
            rePassword: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
            completeReg: "Y",
          },
        });
      }
    }
    if (PropsDt.changepassword !== this.props.changepassword) {
      //  const {changepassword} = nextProps.changepassword;
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (PropsDt.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (PropsDt.changepassword[0].form_error) {
          showAlert("Alert", PropsDt.changepassword[0].form_error);
        } else {
          showAlert("Alert", PropsDt.changepassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    if (field == "rememberme") {
      value = $("#rememberme").prop("checked");
    }
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      username_type: "Mobile",
      login_username: formPayload.email,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });
    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };

  /* for signup - end*/

  /* for MemberShip Sign In - start*/
  fieldChangeMembershipLogin = (field, value) => {
    this.setState(
      update(this.state, {
        fieldsmembershiplogin: { [field]: { $set: value } },
      })
    );
  };

  handleMembershipLogin = () => {
    const formPayloadmemberLgn = this.state.fieldsmembershiplogin;
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      member_phone: formPayloadmemberLgn.mobile,
      member_password: formPayloadmemberLgn.password,
      customer_id: customerId,
    };

    showLoader("member_signinsubmit", "class");
    axios
      .post(apiUrl + "ascentiscrm/memberLogin", qs.stringify(postObject))
      .then((res) => {
        hideLoader("member_signinsubmit", "class");
        if (res.data.status === "ok") {
          this.doLogin(res.data);
        } else {
          cookie.remove("loginpopupTrigger", { path: "/" });
          if (res.data.form_error !== undefined) {
            showAlert(
              "Error",
              res.data.form_error,
              "No",
              "#membership-form-popup"
            );
          } else {
            showAlert(
              "Error",
              res.data.message,
              "No",
              "#membership-form-popup"
            );
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });

          if (customerId !== "") {
            this.props.history.push("/checkout");
            return false;
          }
        }
      });
  };

  /* for MemberShip Register - start*/
  fieldChangeMembership = (field, value) => {
    if (field == "memberterms") {
      value = $("#memberterms").prop("checked");
    }
    this.setState(
      update(this.state, { fieldsmembership: { [field]: { $set: value } } })
    );
  };

  handleMembership = () => {
    var mobileAlreadyExists = $(".memberlogup-popup-body")
      .find("#spn-mobile-error")
      .text();
    var emailAlreadyExists = $(".memberlogup-popup-body")
      .find("#spn-email-error")
      .text();
    console.log("mobileAlreadyExists", mobileAlreadyExists);
    console.log("emailAlreadyExists", emailAlreadyExists);
    if (
      $.trim(mobileAlreadyExists) === "" &&
      $.trim(emailAlreadyExists) === ""
    ) {
      var popupIdTxt = "#pay-omiseconf-memberpopup";
      $.magnificPopup.open({
        items: {
          src: popupIdTxt,
        },
        type: "inline",
        closeOnBgClick: false,
      });
    }
  };

  memberRegisterFun(captureID) {
    const formPayloadmember = this.state.fieldsmembership;
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      member_name: formPayloadmember.firstname,
      member_email: formPayloadmember.email,
      member_phone: formPayloadmember.mobile,
      member_password: formPayloadmember.password,
      member_gender: formPayloadmember.gender,
      member_referrer_code: formPayloadmember.referrercode,
      customer_birthdate: formPayloadmember.birthdate,
      customer_id: customerId,
    };

    axios
      .post(apiUrl + "ascentiscrm/memberRegister", qs.stringify(postObject))
      .then((res) => {
        var customerApiData = res.data;
        if (res.data.status === "ok") {
          var customerId = res.data.result_set.customer_id;
          this.captureOmiseAmount(captureID, customerId, res.data);
        } else {
          cookie.remove("loginpopupTrigger", { path: "/" });
          if (res.data.form_error !== undefined) {
            showAlert("Error", res.data.form_error);
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });

          if (customerId !== "") {
            this.props.history.push("/checkout");
            return false;
          }
        }
      });
  }

  /* Omise Capture Payment */
  captureOmiseAmount(captureID, customerId, customerApiData) {
    var cabtureObjects = {};
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: customerId,
      app_id: appId,
      token: captureID,
      log_id: this.state.omise_log_id,
      outlet_id: cookie.load("orderOutletId"),
    };
    axios
      .post(
        apiUrl + "membershippay/captureAmountOmise",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.setState(
            { stop_authentication: "yes", its_ascentis_memberlogin: "yes" },
            function () {
              this.doLogin(customerApiData);
            }.bind(this)
          );
        } else if (captureRes.data.status === "pending") {
          this.retrieve_charge_details(captureID, customerApiData, customerId);
        } else if (captureRes.data.status === "error") {
          cookie.remove("loginpopupTrigger", { path: "/" });
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : "Your order was not successful.";
          showAlert("Error", omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          /*Get Status of Charge ID*/
          this.retrieve_charge_details(captureID, customerApiData, customerId);
        }
      })
      .catch((error) => {
        this.captureOmiseAmount(captureID, customerId, customerApiData);
      });
  }

  retrieve_charge_details = (captureID, customerApiData, customerId) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      customer_id: customerId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "membershippay/retrieve_charge_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.doLogin(customerApiData);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_charge_details(captureID, customerApiData, customerId);
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : "Your order was not successful.";
          showAlert("Error", msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };

  /* MemberShip Register End */

  /* Change Password */
  fieldChangepassword = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  changepassword = () => {
    var old_password = $(".old_password").val();
    var new_password = $(".new_password").val();
    var confirm_password = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_password,
      password: new_password,
      confirmpassword: confirm_password,
      refrence: cookie.load("UserId"),
    };
    showLoader("change-pass-sbmt", "class");
    var qs = require("qs");
    this.props.getChangePassword(qs.stringify(postObject));
  };

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu, type) {
    if (menu.nav_parent_title === "") {
      if (this.state.menudata) {
        var checkIngVal = 0;
        var liTxt = this.state.menudata.map(function (menuparent, i) {
          if (menu.nav_id == menuparent.nav_parent_title) {
            checkIngVal = 1;
            if (menuparent.nav_type === "0") {
              return (
                <li key={i + 100}>
                  <Link
                    to={"/" + menuparent.nav_title_slug}
                    title={menuparent.nav_title}
                    onClick={(e) => {
                      $("#close_mobile_menu").trigger("click");
                    }}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={i + 100}>
                  <Link
                    to={
                      menuparent.nav_pages != "#"
                        ? "/" + menuparent.nav_pages
                        : ""
                    }
                    title={menuparent.nav_title}
                    target={menuparent.nav_link_type == "blank" ? "_blank" : ""}
                    onClick={(e) => {
                      $("#close_mobile_menu").trigger("click");
                    }}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            }
          }
        }, this);

        if (type === "span" && checkIngVal === 1) {
          return (
            <a href={void 0} className="submenu-arow disbl_href_action"></a>
          );
        } else if (type === "ul" && checkIngVal === 1) {
          return <ul className="submenu_list">{liTxt}</ul>;
        } else {
          return "";
        }
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#" || nav_pages === "/") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "menu") {
      returnClsTx =
        this.props.match.path === "/menu" ||
        this.props.match.path === "/menu/:slugType/:slugValue" ||
        this.props.match.path === "/menu/:slugType/:slugValue/:proValue" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou/:orderId"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : nav_pages;
    }
    return returnClsTx;
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        let nav_pages = menu.nav_pages;
        if (menu.nav_type === "0") {
          nav_pages = menu.nav_title_slug;
        }

        return (
          <li key={i + 100} className={this.menuActiveCls(nav_pages)}>
            {this.createLink(menu)}
            {this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul")}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */
  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
			this.props.history.push('/menu');
			return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  chooseAvailabilityMbl() {
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "Order Now";
    if (availability === deliveryId) {
      showtext = "Delivery";
    } else if (availability === pickupId) {
      showtext = "Self Collection";
    }
    return showtext;
  }

  /* facebook login */
  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];
      var birthday = response.birthday;
      var qs = require("qs");
      var photo = response.picture.data.url;
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }
      /* update DOB */
      var dob = "";
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd");
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      if ($("#rememberme").prop("checked") === true) {
        var loginField = this.state.fields;
        localStorage.setItem("loginuser", base64.encode(loginField.email));
        localStorage.setItem(
          "loginpassword",
          base64.encode(loginField.password)
        );
      } else {
        localStorage.removeItem("loginuser");
        localStorage.removeItem("loginpassword");
      }

      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save("AcentisMemberid", fbloginData.result_set.ascentis_memberid, {
        path: "/",
      });
      cookie.save("AscentisCardNo", fbloginData.result_set.ascentis_card_no, {
        path: "/",
      });
      cookie.save(
        "AscentisMemberPhone",
        fbloginData.result_set.ascentis_member_phone,
        { path: "/" }
      );
      cookie.save(
        "AscentisMemberPassword",
        fbloginData.result_set.ascentis_member_password,
        { path: "/" }
      );
      cookie.save(
        "AscentisMemberCreatedfrom",
        fbloginData.result_set.ascentis_member_createdfrom,
        { path: "/" }
      );
      cookie.save(
        "AscentisMemberPayment",
        fbloginData.result_set.ascentis_member_payment,
        { path: "/" }
      );
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );
      const { history } = this.props;
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: fbloginData.result_set.customer_id,
        availability_id:
          cookie.load("defaultAvilablityId") !== "" &&
          typeof cookie.load("defaultAvilablityId") !== undefined &&
          typeof cookie.load("defaultAvilablityId") !== "undefined"
            ? cookie.load("defaultAvilablityId")
            : deliveryId,
      };

      axios
        .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
        .then((res) => {
          var successMsgTxt =
            this.state.its_ascentis_memberlogin === "yes"
              ? "Your payment is successful. Welcome on board Cedele Reward$ Membership! You may now get to enjoy your Reward$ membership benefits."
              : "Welcome back!";
          showAlert("Success", successMsgTxt);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          var historyvar = history;
          setTimeout(function () {
            if (res.data.status === "ok") {
              if (cookie.load("loginpopupTrigger") === "fromcheckout") {
                cookie.remove("loginpopupTrigger", { path: "/" });
                historyvar.push("/checkout");
              } else {
                historyvar.push("/myaccount");
              }
            } else {
              if (cookie.load("memberloginpopupTrigger") === "fromcheckout") {
                cookie.remove("memberloginpopupTrigger", { path: "/" });
                historyvar.push("/checkout");
              } else {
                historyvar.push("/myaccount");
              }
            }
          }, 500);
        });
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      showAlert("Error", fbloginData.message, "No", "#login-popup");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /* Google Login */
  prepareLoginButton = () => {
    if (this.state.trigerGlogin === false) {
      this.setState({ trigerGlogin: true }, function () {
        setTimeout(function () {
          $(".loginBtn--google").trigger("click");
        }, 1000);
      });
    }
    var current = this;
    this.auth2.attachClickHandler(
      this.refs.googleLoginBtn,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        var resultRes = {
          name: profile.getName(),
          email: profile.getEmail(),
          picture: profile.getImageUrl(),
          id: profile.getId(),
        };
        current.loginGoogle(resultRes);
      },
      (error) => {
        //alert(JSON.stringify(error, undefined, 2));
      }
    );
  };

  googleSDK() {
    window["googleSDKLoaded"] = () => {
      window["gapi"].load("auth2", () => {
        this.auth2 = window["gapi"].auth2.init({
          client_id: googleAppId,
          cookiepolicy: "single_host_origin",
          scope: "profile email",
        });
        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  }

  loginGoogle(response) {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];

      var qs = require("qs");
      var photo = response.picture;
      /* update gender field */

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: firstname,
        login_lastname: lastname,
        login_username: response.email,
        customer_google_id: response.id,
        customer_photo: photo,
      };
      this.setState({ googlestatus: "loading" });
      this.props.getGoogleLoginData(qs.stringify(postObject));
    }
  }

  /*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
    this.setState(
      update(this.state, { fieldscheckout: { [field]: { $set: value } } })
    );
  };

  handleCheckout = () => {
    var formPayload = this.state.fieldscheckout;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.check_firstname,
      customer_email: formPayload.check_email,
      customer_phone: formPayload.check_phone,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    axios
      .post(apiUrl + "guestaccount/create", qs.stringify(postObject))
      .then((response) => {
        hideLoader("otp_verify", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id, {
            path: "/",
          });
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );
          cookie.save("UserMobile", response.data.result_set.customer_phone, {
            path: "/",
          });
          cookie.save("UserEmail", response.data.result_set.customer_email, {
            path: "/",
          });
          cookie.save(
            "AcentisMemberid",
            response.data.result_set.ascentis_memberid,
            { path: "/" }
          );
          cookie.save(
            "AscentisCardNo",
            response.data.result_set.ascentis_card_no,
            { path: "/" }
          );
          cookie.save(
            "AscentisMemberPhone",
            response.data.result_set.ascentis_member_phone,
            { path: "/" }
          );
          cookie.save(
            "AscentisMemberPassword",
            response.data.result_set.ascentis_member_password,
            { path: "/" }
          );
          cookie.save(
            "AscentisMemberCreatedfrom",
            response.data.result_set.ascentis_member_createdfrom,
            { path: "/" }
          );
          cookie.save(
            "AscentisMemberPayment",
            response.data.result_set.ascentis_member_payment,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            { path: "/" }
          );
          cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
          cookie.save("userAccountType", 1, { path: "/" });
          const { history } = this.props;

          if (cookie.load("cateringPay") === "start") {
            showAlert("Success", "Logged in Successfully!");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            history.push(
              "/payment/" +
                cookie.load("orderIdTxt") +
                "/" +
                cookie.load("paymentIdTxt")
            );
            /*history.push("/payment");*/
          } else {
            var qs = require("qs");
            var postObject = {
              app_id: appId,
              reference_id: getReferenceID(),
              customer_id: response.data.result_set.customer_id,
              availability_id: cookie.load("defaultAvilablityId"),
            };

            axios
              .post(
                apiUrl + "cart/update_customer_info",
                qs.stringify(postObject)
              )
              .then((res) => {
                showAlert("Success", "Logged in Successfully!");
                $.magnificPopup.open({
                  items: {
                    src: ".alert_popup",
                  },
                  type: "inline",
                });
                if (res.data.status === "ok") {
                  if (cookie.load("checkoutredirect") === "yes") {
                    cookie.remove("loginpopupTrigger", { path: "/" });
                    history.push("/checkout");
                  } else if (
                    cookie.load("loginpopupTrigger") === "fromcheckout"
                  ) {
                    cookie.remove("loginpopupTrigger");
                    history.push("/checkout");
                  } else if (
                    cookie.load("loginpopupTrigger") === "fromchecatering"
                  ) {
                    cookie.remove("loginpopupTrigger");
                    history.push("/catering");
                  } else if (cookie.load("redirectReservatin") === "Yes") {
                    cookie.remove("redirectReservatin");
                    history.push("/reservation");
                  } else {
                    history.push("/myaccount");
                  }
                } else {
                  if (cookie.load("redirectReservatin") === "Yes") {
                    cookie.remove("redirectReservatin");
                    history.push("/reservation");
                  } else {
                    history.push("/myaccount");
                  }
                }
              });
          }
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  closepopup(event) {
    event.preventDefault();
    this.props.history.push("/menu");
    return false;
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    });

    /* For Advanced Slot Start */
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      slotType: tempVl,
      seleted_ord_slot: tempVl,
      seleted_ord_slotTxt: tempVl,
      seleted_ord_slot_str: tempVl,
      seleted_ord_slot_end: tempVl,
    });
    /* For Advanced Slot End */

    this.destroyCart("Yes");

    var warning_avilablity = $("#warning_avilablity").val();
    var nextavail = this.state.nextavail;
    if (warning_avilablity !== "") {
      var nextavail = warning_avilablity;
    }

    var popupIdtxt = "";
    if (nextavail === deliveryId) {
      popupIdtxt = "#delevery-postcode-popup";
    } else if (nextavail === pickupId) {
      if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else {
        popupIdtxt = "#takeaway-popup";
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
      cookie.remove("outletchosen", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();
    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    /* For Advanced Slot */
    cookie.remove("isAdvanced", { path: "/" });
    cookie.remove("slotType", { path: "/" });
    cookie.remove("orderSlotVal", { path: "/" });
    cookie.remove("orderSlotTxt", { path: "/" });
    cookie.remove("orderSlotStrTime", { path: "/" });
    cookie.remove("orderSlotEndTime", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    /*  var outletIdTxt = this.state.delivery_outlet_id;

  if (outletIdTxt === "") {
    $(".postal_error").html(
      '<span class="error">Go Back and Select your delivery outlet.</span>'
    );
    return false;
  } */

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .all([
        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&skip_timing=Yes&availability_id=" +
            availability +
            "&postal_code=" +
            postalcode +
            "&&postalcode_basedoutlet=yes"
        ),
      ])
      .then(
        axios.spread((res, timeslt) => {
          var deliveryInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("outletchosen", availability, { path: "/" });

            var additionalTatTime =
              res.data.result_set.zone_additional_tat_time !== ""
                ? res.data.result_set.zone_additional_tat_time
                : 0;
            var outletDeliveryTiming =
              res.data.result_set.outlet_delivery_timing !== ""
                ? res.data.result_set.outlet_delivery_timing
                : 0;
            additionalTatTime = 0;
            var outletDeliveryTaT =
              parseInt(outletDeliveryTiming) + parseInt(additionalTatTime);
            var zip_sname =
              res.data.result_set.postal_code_information.zip_sname !== ""
                ? " " + res.data.result_set.postal_code_information.zip_sname
                : "";
            var orderDeliveryAddress =
              res.data.result_set.postal_code_information.zip_buno + zip_sname;
            var orderHandled =
              stripslashes(res.data.result_set.outlet_name) +
              ", Crew will be seeing you in " +
              outletDeliveryTaT +
              " Minutes";

            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
            deliveryInfo["orderOutletName"] = stripslashes(
              res.data.result_set.outlet_name
            );
            deliveryInfo["orderPostalCode"] =
              res.data.result_set.postal_code_information.zip_code;
            deliveryInfo["orderTAT"] = outletDeliveryTaT;
            deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
            deliveryInfo["orderHandled"] = orderHandled;
            deliveryInfo["defaultAvilablityId"] = availability;

            var unitNum = this.showUnitNum(
              res.data.result_set.outlet_unit_number1,
              res.data.result_set.outlet_unit_number2
            );
            var outlet_address_line2 =
              res.data.result_set.outlet_address_line2 !== ""
                ? " " + res.data.result_set.outlet_address_line2
                : "";
            var orderHandledText =
              res.data.result_set.outlet_address_line1 +
              outlet_address_line2 +
              ", " +
              unitNum +
              " Singapore " +
              postalcode;

            deliveryInfo["orderHandledByText"] = orderHandledText;

            this.setState(
              {
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: outletDeliveryTaT,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  ", Singapore " +
                  res.data.result_set.postal_code_information.zip_code,
              },
              function () {
                axios
                  .get(
                    apiUrlV2 +
                      "settings/chkTimeslotIsAvaiable?app_id=" +
                      appId +
                      "&availability_id=" +
                      availability +
                      "&outletId=" +
                      res.data.result_set.outlet_id
                  )
                  .then((timeslt) => {
                    hideLoader("delivery_submit_cls", "class");
                    $.magnificPopup.close();
                    /* Success time slot response */
                    if (timeslt.data.status === "success") {
                      /* For Advanced Slot */
                      var isAdvanced = timeslt.data.isAdvanced,
                        slotType = "0";
                      if (isAdvanced === "yes") {
                        slotType = timeslt.data.slotType;
                      }
                      this.setState({
                        getDateTimeFlg: "yes",
                        isAdvanced: isAdvanced,
                        slotType: slotType,
                      });
                      removeOrderDateTime();
                      removePromoCkValue();

                      $.magnificPopup.open({
                        items: {
                          src: "#awesome-popup",
                        },
                        type: "inline",
                      });
                    } else {
                      $.magnificPopup.open({
                        items: {
                          src: "#outlet-error-popup",
                        },
                        type: "inline",
                      });
                    }
                  });
              }
            );
          }

          /* Error response */
          if (res.data.status === "error") {
            this.setState({ deliveryInfo: deliveryInfo });
            hideLoader("delivery_submit_cls", "class");
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: "#error-postal-popup",
              },
              type: "inline",
            });

            if (first === 0) {
              var mgsTxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Please enter valid postal code.";
              $(".postal_error").html(
                '<span class="error">' + mgsTxt + "</span>"
              );
            }
          }
        })
      );
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          cookie.save("outletchosen", availability, { path: "/" });
          var zip_sname =
            res.data.result_set.postal_code_information.zip_sname !== ""
              ? " " + res.data.result_set.postal_code_information.zip_sname
              : "";
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno + zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              " Singapore " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });
          cookie.save("orderOutletId", res.data.result_set.outlet_id, {
            path: "/",
          });
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            { path: "/" }
          );
          cookie.save(
            "orderPostalCode",
            res.data.result_set.postal_code_information.zip_code,
            { path: "/" }
          );
          cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
            path: "/",
          });
          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", availability, { path: "/" });
          var outlet_address_line2 =
            res.data.result_set.outlet_address_line2 !== ""
              ? " " + res.data.result_set.outlet_address_line2
              : "";
          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            outlet_address_line2 +
            ", Singapore " +
            postalcode;
          cookie.save("orderHandledByText", orderHandledText, { path: "/" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      cookie.save("outletchosen", cookie.load("defaultAvilablityId"), {
        path: "/",
      });
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        this.props.history.push("/menu");
      }
    }
  }

  selectDatetm() {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) {
      showLoader("takeaway-btn-act", "class");

      axios
        .get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            pickupId +
            "&outletId=" +
            seletedOutletId
        )
        .then((res) => {
          hideLoader("takeaway-btn-act", "class");

          /* Success response */
          if (res.data.status === "success") {
            /* For Advanced Slot */
            var isAdvanced = res.data.isAdvanced,
              slotType = "0";
            if (isAdvanced === "yes") {
              slotType = res.data.slotType;
            }
            this.setState({
              getDateTimeFlg: "yes",
              isAdvanced: isAdvanced,
              slotType: slotType,
            });

            removeOrderDateTime();
            removePromoCkValue();

            $.magnificPopup.open({
              items: {
                src: "#awesome-popup",
              },
              type: "inline",
            });
          } else {
            $.magnificPopup.open({
              items: {
                src: "#outlet-error-popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    }
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.pickOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) &&
      Object.keys(pickupInfo).length > 0
        ? "active"
        : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    if (event !== undefined) {
      event.preventDefault();
    }

    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );
    var outlet_address_line2 =
      loaddata.outlet_address_line2 !== ""
        ? " " + loaddata.outlet_address_line2
        : "";
    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      outlet_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }
  openMbrActiveTab(currentTab) {
    this.setState({ member_lginactive: currentTab });
  }
  componentDidUpdate() {}

  getSearchProductList() {
    var orderOutletIdtext = cookie.load("orderOutletId");
    var addquery_txt = "";
    if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
      addquery_txt = "&outletId=" + orderOutletIdtext;
    }
    var searchResult = [];
    var defaultAvilablityId = "";
    if (
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
    ) {
      defaultAvilablityId =
        "&availability=" + cookie.load("defaultAvilablityId");
    }

    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A" +
          defaultAvilablityId +
          addquery_txt
      )
      .then((response) => {
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;

                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                    });
                  }
                }
              }
            }
          }
        }
        if (
          this.props.match.path === "/menu/:slugType/:slugValue" ||
          this.props.match.path === "/menu"
        ) {
          this.props.sateValChange("productlist", searchResult);
        }

        this.setState({
          searchProResult: searchResult,
          selectedProResult: searchResult,
        });
      });
  }

  searchProKeyPress = (event) => {
    $(".search_result").show();

    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item;
        }
      });

    $("#clearSearch").show();
    if (value === "") {
      $("#clearSearch").hide();
    }

    this.setState({ selectedProResult: matches });
  };

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      if ($("#search_result_mbl").length > 0) {
        $("#search_result_mbl").show();
      }

      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/menu/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      if ($("#search_result_mbl").length > 0) {
        $("#search_result_mbl").show();
      }
      return <li className="no-pro-found">No Product found</li>;
    }
  }

  clearSearchFun() {
    var emtytxt = "";
    $(".productsearch").val(emtytxt);
    this.setState({ selectedProResult: this.state.searchProResult });
  }

  ViewProducts(event) {
    var productSlug = event.value;
    this.props.history.push("/menu/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkAblBtn() {
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var actText = "Order Now";
    if (
      availability === deliveryId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Delivery";
    } else if (
      availability === pickupId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Self Collection";
    }

    return (
      <a
        href="/"
        onClick={this.closepopup.bind(this)}
        className="hordertype_btn"
        title={actText}
      >
        {actText}
      </a>
    );
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  checkActiveDivMbl(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "myaccount";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <Link to={"/myaccount"} className="myacunt_act" title="My Account">
            {" "}
            My Account{" "}
          </Link>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl);
    } else {
      $("#postalcode1").val(pstVl);
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        /* For Advanced Slot */
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        /* For Advanced Slot */
        var isAdvanced = this.state.isAdvanced;
        var slotType = this.state.slotType;
        var orderSlotVal = "",
          orderSlotTxt = "",
          orderSlotStrTime = "",
          orderSlotEndTime = "";
        if (slotType === "2") {
          orderSlotVal = this.state.seleted_ord_slot;
          orderSlotTxt = this.state.seleted_ord_slotTxt;
          orderSlotStrTime = this.state.seleted_ord_slot_str;
          orderSlotEndTime = this.state.seleted_ord_slot_end;
        }
        cookie.save("isAdvanced", isAdvanced, { path: "/" });
        cookie.save("slotType", slotType, { path: "/" });
        cookie.save("orderSlotVal", orderSlotVal, { path: "/" });
        cookie.save("orderSlotTxt", orderSlotTxt, { path: "/" });
        cookie.save("orderSlotStrTime", orderSlotStrTime, { path: "/" });
        cookie.save("orderSlotEndTime", orderSlotEndTime, { path: "/" });
        /* For Advanced Slot End */

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });
        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });
        cookie.save("outletchosen", orderInfoData["defaultAvilablityId"], {
          path: "/",
        });

        $.magnificPopup.close();
        if (
          this.props.match.path === "/menu" ||
          this.props.match.path === "/menu/:slugType/:slugValue/:proValue"
        ) {
          location.reload();
        } else if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", { path: "/" });
          this.props.history.push("/");
        } else if (cookie.load("redirectfavourite") === "Yes") {
          cookie.remove("redirectfavourite", { path: "/" });
          this.props.history.push("/favourite");
        } else {
          this.props.history.push("/menu");
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/;
    if (item == "cardNumber") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value);
        var cardnumberLenght = cardnumber.length;
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber });
          this.cardValidation(cardnumber);
        }
      }
    } else if (
      item == "expiration_month" ||
      item == "expiration_year" ||
      item == "security_code"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
    } else {
      this.setState({ [item]: event.target.value });
    }

    this.setState({
      omisenameerror: "",
      omisecardrror: "",
      omisemontherror: "",
      omiseyearerror: "",
      omisecodeerror: "",
    });
  };

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  cardValidation(carnumber) {
    var imagename = "";
    if (carnumber != "") {
      var single = carnumber.substring(0, 1);
      var double = carnumber.substring(0, 2);

      if (single == 4) {
        imagename = "visa";
      } else if (double == 34 || double == 37) {
        imagename = "american";
      } else if (double >= 51 && double <= 55) {
        imagename = "master";
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = "discover";
      }
    }

    this.setState({ cardImage: imagename });
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.holdername == "") {
      errorFlg++;
      omisenameerror = "omise-form-error";
    }
    if (this.state.cardNumber == "") {
      errorFlg++;
      omisecardrror = "omise-form-error";
    }
    if (this.state.expiration_month == "") {
      errorFlg++;
      omisemontherror = "omise-form-error";
    }
    if (this.state.expiration_year == "") {
      errorFlg++;
      omiseyearerror = "omise-form-error";
    }
    if (this.state.security_code == "") {
      errorFlg++;
      omisecodeerror = "omise-form-error";
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror,
    });

    var settingsArr = this.props.globalsettings;
    var omisePublicKey = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        omisePublicKey = settingsArr[0].result_set.omise_public_key;
      }
    }

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");
      Omise.setPublicKey(omisePublicKey);
      var current = this;
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false,
      };
      Omise.createToken("card", card, function (statusCode, response) {
        hideLoader("omise-pay-btn", "class");
        if (statusCode === 200) {
          if (
            response.object == "error" ||
            !response.card.security_code_check
          ) {
            var msgArr = Array();
            msgArr["message"] =
              response.object == "error"
                ? response.message
                : "Invalid card details.";
            current.showOmiselert(msgArr);
          } else {
            $.magnificPopup.close();
            current.setState(
              {
                omise_tokken_response: response,
                omise_tokken_card_id: response.card.id,
                omise_tokken_id: response.id,
              },
              () => {
                current.onProcessOmiseToken(response);
              }
            );
          }
        } else {
          var msgArr = Array();
          msgArr["message"] =
            response.message !== ""
              ? response.message
              : "Sorry!. Error from omise.";
          current.showOmiselert(msgArr);
        }
      });
    } else {
      return false;
    }
  }

  showOmiselert(msgArr) {
    var dataTimeoutId = $(".omise-error-info-div").attr("data-timeoutid");
    clearTimeout(dataTimeoutId);
    $(".omise-error-info-div").hide();
    $(".omise-error-msg").html(msgArr["message"]);
    $("#omise-error-info-div").fadeIn();
    var timeoutid = setTimeout(function () {
      $(".omise-error-info-div").hide();
    }, 6000);
    $(".omise-error-info-div").attr("data-timeoutid", timeoutid);
  }

  /* post stripe account */
  onProcessOmiseToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing-memberpay",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var payAmount = parseFloat(this.state.member_reg_amount).toFixed(2);
    var formPayloadmember = this.state.fieldsmembership;
    var requestType =
      "Cedele-" + cookie.load("orderOutletId") + "-" + formPayloadmember.mobile;

    /*  load process html */
    var postObject = {};
    postObject = {
      app_id: appId,
      token: token.id,
      customer_id: cookie.load("UserId"),
      customer_mobile: formPayloadmember.mobile,
      outlet_id: cookie.load("orderOutletId"),
      paid_amount: payAmount,
      request_type: requestType,
      outlet_name:
        typeof cookie.load("orderOutletName") !== "undefined" &&
        typeof cookie.load("orderOutletName") !== undefined
          ? cookie.load("orderOutletName") + "- Registration"
          : "",
    };

    axios
      .post(apiUrl + "membershippay/authOmise", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.result_set.payment_reference_1;
          var omiseLogId = res.data.result_set.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.memberRegisterFun(captureID);
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          if (res.data.message == "token was already used") {
            this.omise_search_history();
          } else {
            $.magnificPopup.close();
            var errerTxt =
              res.data.message !== ""
                ? res.data.message
                : "Error code: 1001 Oops! Something went wrong! Please try again.";
            this.paymentFail("Error", errerTxt);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        } else {
          var currents = this;
          setTimeout(
            function () {
              currents.omise_search_history();
            }.bind(this),
            2000
          );
        }
      })
      .catch((error) => {
        var currentcatch = this;
        setTimeout(
          function () {
            currentcatch.omise_search_history();
          }.bind(this),
          2000
        );
      });
  };

  omise_search_history = () => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      app_id: appId,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "membershippay/omise_search_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.captureID;
          var omiseLogId = res.data.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.memberRegisterFun(captureID);
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        }
      });
  };

  pageReload = () => {
    setTimeout(function () {
      window.location.reload();
    }, 15000);
  };

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.validateimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Validating card information.</p>
        </div>
      </div>
    );
  }
  /**/

  /* show online payment mode loading */
  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>User details saved now.</p>
        </div>
      </div>
    );
  }
  /**/

  /* show online payment mode loading */
  amountCaptureLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.completingpaymentimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Completing your online payment.</p>
        </div>
      </div>
    );
  }
  /**/

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  searBlkFun(typeTxt) {
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var liTopCls = "htico_search";
    var scearDivTopCls = "hsearch_sec";
    var triggerActCls = "hsearch_trigger";
    var searchResultCls = "search_result";
    var searchIconcls = "search_i_icon";
    if (typeTxt === "mbl") {
      liTopCls = "htico_search_mbl";
      scearDivTopCls = "hsearch_sec_mbl";
      triggerActCls = "hsearch_trigger_mbl";
      searchResultCls = "search_result_mbl";
      searchIconcls = "fa fa-search";
    }

    return (
      <li className={liTopCls}>
        <a
          href="#"
          /* data-effect="mfp-zoom-in" */
          className={triggerActCls}
          title="Search"
        >
          <img src={loupe} alt="" />
        </a>
        <div className={scearDivTopCls}>
          <div className="input-sec">
            <input
              type="text"
              name="searchkey"
              className="productsearch"
              id={"productsearch_" + typeTxt}
              placeholder="Search..."
              autoComplete="off"
              onKeyUp={this.searchProKeyPress}
            />

            <a
              href="/"
              id="clearSearch"
              onClick={this.clearSearchFun.bind(this)}
              className="search_text_clear disbl_href_action"
            >
              X
            </a>
          </div>

          <ul className={searchResultCls} id={searchResultCls}>
            {this.loadProSearchList()}
          </ul>
        </div>
      </li>
    );
  }

  closeMemberRegister() {
    $.magnificPopup.close();
  }
  sateValChange = (field, value) => {
    if (field === "open-member-login") {
      this.setState({ member_lginactive: "sign-in" });
    }
  };
  render() {
    var currenturl = window.location.href;
    var substring = "menu";
    var isCheckout = "checkout";

    var showCatryName =
      typeof this.props.showCatryName !== "undefined"
        ? this.props.showCatryName
        : "Category Name";

    /* For Advanced Slot Start */
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var settingsArr = this.props.globalsettings;
    var advancedTimeslotEnable = "0";
    var omiseEnvirText = "";
    var omisePublicKey = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        advancedTimeslotEnable =
          settingsArr[0].result_set.client_advanced_timeslot_enable;
        omiseEnvirText = settingsArr[0].result_set.omise_envir;
        omisePublicKey = settingsArr[0].result_set.omise_public_key;
      }
    }

    return (
      <>
        {this.state.instantorders !== "" && (
          <div className="top_header_blk">{this.state.instantorders}</div>
        )}

        <header>
          <div className="header-top-cls">
            <div className="container">
              <div className="logo-main-section">
                <div className="menu_icon trigger_menunav_act">
                  <div className="symbol-merge">
                    <span className="icon-bar icon-bar1"></span>
                    <span className="icon-bar icon-bar2"></span>
                    <span className="icon-bar icon-bar3"></span>
                  </div>
                  <Link to={"/menu"}>MENU</Link>
                </div>
                <div className="mobile-menu">
                  <div className="mobile-menu-close">
                    <span id="close_mobile_menu"></span>
                  </div>
                  <div className="mobile-menu-body">
                    <ul className="menu">
                      {this.state.menudata && this.listMainNavigation()}
                      {!cookie.load("UserId") && (
                        <>
                          <li>
                            <a
                              href="#login-popup"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link htico_sign"
                              title="Login"
                            >
                              <span>Login</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#signup-popup"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link"
                              title="Sign up"
                            >
                              {" "}
                              <span>Sign Up</span>
                            </a>
                          </li>
                        </>
                      )}

                      {cookie.load("UserId") && (
                        <>
                          <li>
                            <Link
                              className={
                                this.props.match.path === "/myaccount" ||
                                this.props.match.path === "/myorders" ||
                                this.props.match.path === "/rewards" ||
                                this.props.match.path === "/voucher" ||
                                this.props.match.path === "/mypromotions"
                                  ? "htico_sign active"
                                  : "htico_sign"
                              }
                              to="/myaccount"
                              title="My Account"
                            >
                              <i /> <span>My Account</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="htico_sign"
                              to="/logout"
                              title="Logout"
                            >
                              <i /> <span>Logout</span>
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="logo">
                  <Link to={"/"} title={projectTitle}>
                    <img src={mainLogo} alt={projectTitle} />
                  </Link>
                </div>
                <div className="hmenu_sec">
                  <ul className="hmenu_list desktop_hmenu_list">
                    {this.state.menudata && this.listMainNavigation()}
                  </ul>
                </div>
                <div className="hmenu-login-section">
                  <ul className="hmenu-login-act">
                    {/* <li className="hordertype_sec">{this.checkAblBtn()}</li> */}
                    {this.searBlkFun("mbl")}
                    {this.searBlkFun("desktop")}
                    {!cookie.load("UserId") && (
                      <li className="hsign_sec">
                        <a
                          href="#login-popup"
                          data-effect="mfp-zoom-in"
                          className="open-popup-link htico_sign"
                          title="Login"
                        >
                          <span>Login</span>
                        </a>
                        <span>|</span>
                        <a
                          href="#signup-popup"
                          data-effect="mfp-zoom-in"
                          className="open-popup-link"
                          title="Sign up"
                        >
                          {" "}
                          <span>Sign Up</span>
                        </a>
                      </li>
                    )}

                    {cookie.load("UserId") && (
                      <li className="hsign_sec">
                        <Link
                          className={
                            this.props.match.path === "/myaccount" ||
                            this.props.match.path === "/myorders" ||
                            this.props.match.path === "/rewards" ||
                            this.props.match.path === "/voucher" ||
                            this.props.match.path === "/mypromotions"
                              ? "htico_sign active"
                              : "htico_sign"
                          }
                          to="/myaccount"
                          title="My Account"
                        >
                          <i /> <span>My Account</span>
                        </Link>
                        <span>|</span>
                        <Link
                          className="htico_sign"
                          to="/logout"
                          title="Logout"
                        >
                          <i /> <span>Logout</span>
                        </Link>
                      </li>
                    )}
                    <li className="htico_cart">
                      <Cartsidebar
                        {...this.props}
                        headerState={this.state}
                        prpSateValChange={this.props.sateValChange}
                        setdateTimeFlg={this.setdateTimeFlg}
                        pageName="header"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* mobile_accountdel_body - start */}
          {/* this.props.match.path !== "/menu" &&
            this.props.match.path !== "/menu/:slugType/:slugValue" &&
            this.props.match.path !==
              "/menu/:slugType/:slugValue/:proValue" && (
              <div className="mobile-order-nowbtn">
                <Link
                  className="mobile_account_menu_click"
                  to="/ttt"
                  onClick={this.closepopup.bind(this)}
                >
                  Order Now
                </Link>
              </div>
            ) */}

          {currenturl.includes(substring) && (
            <div className="hcategory_sec">
              <a href="/" className="hcategory_selected_text disbl_href_action">
                <strong>{showCatryName}</strong>{" "}
                <span className="hcategory_trigger">
                  View More <img src={threeline} alt="" />
                </span>
              </a>
              <div className="hcategory_menu ">
                <MenuNavigationmob />
              </div>
            </div>
          )}

          <div
            className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
            id="jquery-success-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              type="button"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-success-msg">
              Nice! Products added to your cart
            </p>{" "}
          </div>

          <div
            className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
            id="jquery-error-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-error-msg">Something went wrong</p>{" "}
          </div>

          <div
            className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
            id="jquery-common-success-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              type="button"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-success-msg">
              Nice! Products added to your cart
            </p>{" "}
          </div>

          <div
            className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
            id="jquery-common-error-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-error-msg">Something went wrong</p>{" "}
          </div>

          {/* login popup */}

          <div id="login-popup" className="mfp-hide popup_sec login-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-lhs">
                <img src={mainLogo} alt="Logo" />
                <a
                  href="#signup-popup"
                  data-effect="mfp-zoom-in"
                  className="button open-popup-link"
                >
                  Sign Up
                </a>
                <p>Sign up as a Regular Member</p>
                <a
                  href="#guest-popup"
                  data-effect="mfp-zoom-in"
                  className="open-popup-link button"
                >
                  Continue As Guest
                </a>
                <a
                  href="#membership-content-popup"
                  data-effect="mfp-zoom-in"
                  className="open-popup-link button"
                >
                  REWARD$ PROGRAMME
                </a>
              </div>
              <div className="full-login-rhs">
                <div className="full-login-rhs-header">
                  <h3> Have An Account? </h3>
                  <br />
                  <p>
                    This account is for use of CEDELE.com only. To sign up for
                    Cedele’s Reward$ Programme, click{" "}
                    <a
                      href="#membership-content-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link underline-cls"
                    >
                      here.
                    </a>
                  </p>
                </div>
                <Login
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleSignin}
                  sateValChange={this.sateValChange}
                  onInvalid={() => console.log("Form invalid!")}
                />
                {/* <div className="popup-footer">
                  <div className="or-seperator">
                    <span>Or Sign in With</span>
                  </div>
                  <button
                    className="loginBtn loginBtn--google"
                    ref="googleLoginBtn"
                    onClick={this.googleSDK.bind()}
                  >
                    <i className="fa fa-google" aria-hidden="true"></i> Sign in
                    with Google
                  </button>
                  <FacebookLogin
                    appId={fbAppId}
                    fields="name,email,picture,first_name,last_name,birthday,gender"
                    callback={this.responseFacebook}
                    scope="public_profile,email,user_birthday"
                    cssclassName="button  fa fa-facebook"
                    redirectUri={baseUrl}
                    icon="fa-facebook"
                    textButton="Sign in with Facebook"
                  />
                </div> */}
              </div>
            </div>
          </div>

          {/* Signup popup */}
          <div id="signup-popup" className="mfp-hide popup_sec signup-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-lhs">
                <img src={mainLogo} alt="Logo" />
                <a
                  href="#login-popup"
                  data-effect="mfp-zoom-in"
                  className="button open-popup-link"
                >
                  Sign In
                </a>
                <a
                  href="#guest-popup"
                  data-effect="mfp-zoom-in"
                  className="open-popup-link button"
                >
                  Continue As Guest
                </a>
                <a
                  href="#membership-content-popup"
                  data-effect="mfp-zoom-in"
                  className="open-popup-link button"
                >
                  REWARD$ PROGRAMME
                </a>
              </div>
              <div className="full-login-rhs">
                <div className="full-login-rhs-header">
                  <h3>Create an Account </h3>
                  <br />
                  <p>
                    “This login is for use of CEDELE.com only. To sign in to the
                    Cedele Reward$ Programme, click{" "}
                    <a
                      href="#membership-content-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link underline-cls"
                    >
                      here.
                    </a>
                  </p>
                </div>
                <Signup
                  fields={this.state.fieldssignup}
                  onChange={this.fieldChangeSignup}
                  onValid={this.handleSignup}
                  onInvalid={() => console.log("Form invalid!")}
                />
                {/* <div className="popup-footer">
                  <div className="or-seperator">
                    <span>Or Sign in With</span>
                  </div>
                  <button
                    className="loginBtn loginBtn--google"
                    ref="googleLoginBtn"
                    onClick={this.googleSDK.bind()}
                  >
                    <i className="fa fa-google" aria-hidden="true"></i> Sign in
                    with Google
                  </button>
                  <FacebookLogin
                    appId={fbAppId}
                    fields="name,email,picture,first_name,last_name,birthday,gender"
                    callback={this.responseFacebook}
                    scope="public_profile,email,user_birthday"
                    cssclassName="btn btn-black  fa fa-facebook"
                    redirectUri={baseUrl}
                    icon="fa-facebook"
                    textButton="&nbsp; Login with Facebook"
                  />
                </div> */}
              </div>
            </div>
          </div>

          {/* Guest Checkout */}
          <div id="guest-popup" className="mfp-hide popup_sec signup-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-lhs">
                <img src={mainLogo} alt="Logo" />
                <a
                  href="#login-popup"
                  data-effect="mfp-zoom-in"
                  className="button open-popup-link"
                >
                  Sign In
                </a>
                <a
                  href="#signup-popup"
                  data-effect="mfp-zoom-in"
                  className="button open-popup-link"
                >
                  Sign Up
                </a>
                <p>Sign up as a Regular Member</p>
                <a
                  href="#membership-content-popup"
                  data-effect="mfp-zoom-in"
                  className="open-popup-link button"
                >
                  REWARD$ PROGRAMME
                </a>
              </div>
              <div className="full-login-rhs">
                <div className="full-login-rhs-header">
                  <h3> Guest Login </h3>
                  <p>
                    “If you have previously purchased from CedeleMarket, please
                    use the{" "}
                    <a
                      href="#login-popup"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link"
                    >
                      <u>SIGN IN</u>
                    </a>{" "}
                    button”
                  </p>
                </div>
                <Guestcheckout
                  fields={this.state.fieldscheckout}
                  onChange={this.fieldChangecheckout}
                  onValid={this.handleCheckout}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Forgot Password Popup */}

          <div
            id="forgot-password-popup"
            className="mfp-hide popup_sec forgot-password-popup"
          >
            <div className="pop-whole full-login-new">
              <div className="full-login-lhs">
                <img src={mainLogo} alt="Logo" />
                <a
                  href="#login-popup"
                  data-effect="mfp-zoom-in"
                  className="button open-popup-link"
                >
                  Sign In
                </a>
                <a
                  href="#guest-popup"
                  data-effect="mfp-zoom-in"
                  className="open-popup-link button"
                >
                  Continue As Guest
                </a>
              </div>
              <div className="full-login-rhs">
                <div className="full-login-rhs-header">
                  <h3>Forgot your password?</h3>
                  <p>You can reset your password here.</p>
                </div>
                <Forgotpassword
                  fields={this.state.fieldsfgtpassword}
                  onChange={this.fieldforgot}
                  onValid={this.forgotpassword}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Change Password */}
          <div
            id="change-password-popup"
            className="white-popup mfp-hide popup_sec changepw_popup"
          >
            <div className="pouup_in">
              <div id="form-msg"></div>
              <h3 className="title1 text-center">Change Password</h3>
              <Changepassword
                fields={this.state.fieldschpassword}
                onChange={this.fieldChangepassword}
                onValid={this.changepassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>

          {/*Payment omise confirm popup Start*/}
          {!currenturl.includes(isCheckout) && (
            <div
              id="pay-omiseconf-memberpopup"
              className="white-popup mfp-hide popup_sec pay-omiseconf-popup pay-omiseconf-memberpopup"
            >
              <div
                className="omise-error-info-div"
                id="omise-error-info-div"
                data-timeoutid=""
                style={{ display: "none" }}
              >
                <div className="container">
                  <span className="omise-error-icon">
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <a
                    href="#"
                    className="omise_alert_close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={(evt) => {
                      evt.preventDefault();
                    }}
                  >
                    {" "}
                    <span aria-hidden="true">×</span>{" "}
                  </a>
                  <p className="omise-error-msg">Something went wrong</p>
                </div>
              </div>

              {omiseEnvirText === "dev" && (
                <div className="omisepay-mode-option">Test Mode</div>
              )}

              <div className="custom_alert">
                <div className="custom_alertin">
                  <div className="alert_height">
                    <div className="omise_pay_header">
                      <span className="omise-pay-img">
                        <img className="makisan-logo-img" src={stripeImage} />
                      </span>
                      <span className="omise-pay-title">{projectTitle}</span>
                    </div>
                    <div className="omise_pay_body">
                      <div className="omisepop_in">
                        <div className="form_sec">
                          <div
                            className={"uname-box " + this.state.omisenameerror}
                          >
                            <i className="fa fa-user" data-unicode="f007"></i>
                            <input
                              type="text"
                              id="holdername"
                              className="form-control"
                              placeholder="Card Holder Name"
                              value={this.state.holdername}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "holdername"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "card-numbr-div " + this.state.omisecardrror
                            }
                          >
                            <i
                              className="fa fa-credit-card"
                              data-unicode="f09d"
                            ></i>
                            <input
                              type="text"
                              className={"form-control " + this.state.cardImage}
                              placeholder="Card Number"
                              value={this.state.cardNumber}
                              id="omise_card_number"
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "cardNumber"
                              )}
                            />
                            <span className="card"></span>
                          </div>

                          <div className="form_expire_row">
                            <div className="expire_row_inner">
                              <div
                                className={
                                  "expire_left " + this.state.omisemontherror
                                }
                              >
                                <i
                                  className="fa fa-calendar-o"
                                  data-unicode="f133"
                                ></i>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength="2"
                                  id="expiration_month"
                                  placeholder="MM"
                                  value={this.state.expiration_month}
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    "expiration_month"
                                  )}
                                />
                              </div>

                              <div
                                className={
                                  "expire_mdl " + this.state.omiseyearerror
                                }
                              >
                                <input
                                  type="text"
                                  placeholder="YY"
                                  className="form-control"
                                  maxLength="4"
                                  id="expiration_year"
                                  value={this.state.expiration_year}
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    "expiration_year"
                                  )}
                                />
                              </div>

                              <div
                                className={
                                  "expire_right " + this.state.omisecodeerror
                                }
                              >
                                <i
                                  className="fa fa-lock"
                                  data-unicode="f023"
                                ></i>
                                <input
                                  type="password"
                                  maxLength="3"
                                  className="form-control"
                                  id="security_code"
                                  placeholder="CVV"
                                  value={this.state.security_code}
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    "security_code"
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="alt_btns">
                            {omisePublicKey !== "" && (
                              <button
                                onClick={this.getOmiseToken.bind(this)}
                                className="button omise-pay-btn"
                              >
                                Pay {stripeCurrency}{" "}
                                {parseFloat(
                                  this.state.member_reg_amount
                                ).toFixed(2)}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*payment omise confirm popup - End*/}

          {!currenturl.includes(isCheckout) && (
            <div
              id="processing-popup"
              className="white-popup mfp-hide popup_sec processing processing-memberpay"
            >
              <div className="pouup_in">
                <h3 className="title1 text-center">
                  We Are Processing Your Order
                </h3>
                <div className="process_inner">
                  {this.onlinePaymentLoading()}
                  {this.orderBCLoading()}
                  {this.amountCaptureLoading()}
                </div>
              </div>
            </div>
          )}

          {/* Membership Content */}
          <div
            id="membership-content-popup"
            className="white-popup mfp-hide msc-pop"
          >
            <a
              href="#login-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link goback-msc"
            >
              Go Back
            </a>
            <div className="msc-inner">
              <div className="msc-header">
                <img src={mainLogo} alt="Logo" />
                <span>Rewards Membership Program</span>
              </div>
              <div className="member-ship-content">
                <h5> Thank you for being a Cedele Reward$ Member!!</h5>
                <p>
                  With your Cedele membership, enjoy exclusive member
                  privileges, promotions, birthday treats and event invites.
                </p>
                <h4>EXCLUSIVE MEMBER PRIVILEGES </h4>
                <p>One Cedele $-point is equal to 1 Singapore dollar.</p>
                <ul>
                  <li>
                    <strong> 5% Rebates*-</strong> on purchases from{" "}
                    <u>
                      Cedele.com, Cedele outlets, Toss & Turn, and Workspace
                      Espresso
                    </u>
                  </li>
                  <li>
                    <strong>DOUBLE Rebates*</strong> during your birthday month
                    on purchases unless otherwise stated.
                  </li>

                  <li>
                    {" "}
                    Rebates are redeemable online at Cedele.com and in-store at
                    any{" "}
                    <u>Cedele outlets, Toss & Turn, and Workspace Espresso</u>
                  </li>
                </ul>
                <p>
                  <em>
                    *Rebates calculated based on nett expenditure on eligible
                    items, after any promotional discounts
                  </em>
                </p>

                <p>
                  If you have any questions on Cedele Rewards, please contact us
                  at{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("/contact-us", "_blank");
                    }}
                    href="/contact-us"
                  >
                    hello@cedele.com
                  </a>
                </p>

                <p>We look forward to rewarding you soon!</p>
              </div>
              <div className="memeber-shop-footer">
                <a
                  href="#membership-form-popup"
                  data-effect="mfp-zoom-in"
                  className="open-popup-link button"
                >
                  Register | Sign In Now
                </a>
              </div>
            </div>
          </div>

          {/* Membership Form */}
          <div
            id="membership-form-popup"
            className="white-popup mfp-hide msc-pop membership-form-popupcls"
          >
            <a
              href="#membership-content-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link goback-msc"
            >
              Go Back
            </a>
            <div className="msc-inner">
              <div className="msc-header">
                <img src={mainLogo} alt="Logo" />
                <span>Rewards Membership Program</span>
              </div>

              <ul className="nav nav-tabs text-center">
                <li
                  className={
                    this.state.member_lginactive === "sign-up" ? "active" : ""
                  }
                >
                  <a
                    data-toggle="tab"
                    href={void 0}
                    onClick={this.openMbrActiveTab.bind(this, "sign-up")}
                    aria-expanded="true"
                  >
                    <span>Sign Up</span>
                  </a>
                </li>
                <li
                  className={
                    this.state.member_lginactive === "sign-in" ? "active" : ""
                  }
                >
                  <a
                    data-toggle="tab"
                    href={void 0}
                    onClick={this.openMbrActiveTab.bind(this, "sign-in")}
                    aria-expanded="false"
                  >
                    <span>Sign In</span>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  className={
                    this.state.member_lginactive === "sign-up"
                      ? "msc-form"
                      : "msc-form msc-form-hide"
                  }
                >
                  <div className="msc-form-header">
                    <h3 className="title1 text-center">
                      Sign Up For Cedele Reward$ MemberShip
                    </h3>
                  </div>
                  <Membersignup
                    fields={this.state.fieldsmembership}
                    onChange={this.fieldChangeMembership}
                    onValid={this.handleMembership}
                    sateValChange={this.sateValChange}
                    onInvalid={() => console.log("Form invalid!")}
                  />
                </div>

                <div
                  className={
                    this.state.member_lginactive === "sign-in"
                      ? "msc-form"
                      : "msc-form msc-form-hide"
                  }
                >
                  <div className="msc-form-header">
                    <h3 className="title1 text-center">
                      Sign In For Cedele Reward$ MemberShip
                    </h3>
                  </div>
                  <Membersignin
                    fields={this.state.fieldsmembershiplogin}
                    onChange={this.fieldChangeMembershipLogin}
                    onValid={this.handleMembershipLogin}
                    onInvalid={() => console.log("Form invalid!")}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Order popup - start */}
          <div
            id="order-popup"
            className="white-popup mfp-hide popup_sec order_popup"
          >
            <div className="order-body">
              <h2>Order Now</h2>
              <p>Select your order type</p>
              <div className="order_delivery_row">
                <div className="order_delivery_col">
                  <ul className="order_delivery_item">
                    <li>
                      <a
                        href="/"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          deliveryId
                        )}
                        className={this.checkActiveDivHd(deliveryId)}
                      >
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={deliveryImg} />
                          <h3>Delivery</h3>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a
                        href="/"
                        onClick={this.chooseAvailabilityFun.bind(
                          this,
                          pickupId
                        )}
                        className={this.checkActiveDivHd(pickupId)}
                      >
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={takeawayImg} />
                          <h3>Self Collection</h3>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Order popup - end */}

          {/* Delivery Popup - Start */}
          <div
            id="delevery-popup"
            className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
          >
            <div className="order-body">
              <div className="self_popup_hea_row">
                <div className="self_popup_hea_col_left">
                  <img className="outlet-scooter-img" src={deliveryImg} />
                </div>
                <div className="self_popup_hea_col">
                  <h2>Please Select</h2>
                  <p>Your Delivery Outlet</p>
                </div>
              </div>

              <div className="self_pop_row">
                <div className="self_pop_col self_pop_col_right">
                  <div className="self_pop_item">
                    <div className="self_pop_locbx">
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Search Outlet</label>
                          <input
                            type="input"
                            className="form-control input-focus"
                            onKeyUp={this.handleKeyPressDly}
                          />
                          <div className="outlet_error"></div>
                        </div>
                      </div>
                    </div>

                    <div className="self_outlet">
                      <h2>Nearby Outlets</h2>
                      <ul className="self_outlet_inner">
                        {this.loadDeliveryOutletsList()}
                      </ul>
                      <a
                        className="button disbl_href_action"
                        id="delivery-continue-link"
                        href="/"
                        onClick={this.selectDlyOutlet.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Delivery Popup - end */}

          {/*  Delivery Postal code Popup - start */}
          <div
            id="delevery-postcode-popup"
            className="white-popup mfp-hide popup_sec delevery_popup"
          >
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title">
                    <img className="pop-scooter-img" src={deliveryWtImg} />
                    <h2 className="text-uppercase">Let us know</h2>
                    <small>Your Delivery Location</small>
                  </div>
                </div>
              </div>
              <div className="popup_right">
                <div className="innervmid_in">
                  {cookie.load("UserId") && (
                    <div className="address-list-cls address-list-main">
                      {this.userAddressList(1)}
                    </div>
                  )}

                  <div className="form-group">
                    <div
                      className={
                        this.state.secondaryaddresslist.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>Enter your postal code</label>
                      <input
                        type="text"
                        id="postalcode"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                      />
                      <div className="postal_error"></div>
                    </div>
                  </div>
                  <div className="btn_sec">
                    <div className="two-button-row">
                      <div className="go_second">
                        {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
                        <a
                          href="/"
                          onClick={this.gobckOutletpopup.bind(this)}
                          className="button button-left disbl_href_action"
                          title="Go Back"
                        >
                          Go Back
                        </a>
                      </div>
                      <div className="con_first delivery_submit_cls">
                        {/*<input type="button" onClick={this.selectOutlet.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />*/}
                        <input
                          type="button"
                          onClick={this.findOutletBasedZone.bind(
                            this,
                            1,
                            deliveryId
                          )}
                          className="button button-right delivery_submit"
                          value="Continue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Delevery Popup - end */}

          {/* Delevery Popup error - start */}
          <div
            id="error-postal-popup"
            className="white-popup mfp-hide popup_sec error_postal_popup"
          >
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title poptt_icontop text-center">
                    <img src={iconUnhappy} />
                    <h2 className="text-uppercase">Sorry </h2>
                    <small>We cant find your postal code</small>
                  </div>
                </div>
              </div>
              <div className="popup_right">
                <div className="innervmid_in">
                  {/*<h4>Enter your postal code</h4>*/}

                  {cookie.load("UserId") && (
                    <div className="address-list-cls address-list-error">
                      {this.userAddressList(2)}
                    </div>
                  )}

                  <form className="form_sec">
                    <div className="form-group">
                      <p>
                        If your postal code is not listed, please WhatsApp us at{" "}
                        <a href="https://wa.me/+6596458806" target="_blank">
                          +65 9645 8806
                        </a>
                        <br />
                      </p>
                      <div
                        className={
                          this.state.secondaryaddresslist.length > 0
                            ? "focus-out focused"
                            : "focus-out"
                        }
                      >
                        <label>Enter your postal code</label>
                        <input
                          type="text"
                          id="postalcode1"
                          pattern="\d*"
                          maxLength="6"
                          className="form-control input-focus"
                        />
                        <div className="postal_error"></div>
                      </div>
                    </div>
                    <div className="btn_sec delivery_submit_cls delivery_submit_div">
                      {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                      <input
                        type="button"
                        onClick={this.findOutletBasedZone.bind(
                          this,
                          0,
                          deliveryId
                        )}
                        className="button delivery_submit"
                        value="Continue"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Delevery Popup error - Start */}

          {/* success popup - Start */}
          <div
            id="awesome-popup"
            className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
          >
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title">
                    <h2 className="text-uppercase">
                      {this.state.seletedAvilablityId === deliveryId
                        ? "Delivery"
                        : "Self Collection"}
                    </h2>
                    <p>Let us know your delivery date & time</p>
                  </div>
                </div>
              </div>
              <div className="popup_right">
                <div className="innervmid_in">
                  <div className="pop_title">
                    <img className="pop-scooter-img" src={Win} />
                    <h2 className="text-uppercase">Awesome</h2>
                    {this.state.seletedAvilablityId === deliveryId ? (
                      <p>We can deliver to you!</p>
                    ) : (
                      <p>You Can Pickup From</p>
                    )}
                  </div>
                  {this.state.seletedAvilablityId === deliveryId ? (
                    <div className="awesome_del">
                      <h5>Your Delivery Address :</h5>
                      <h2>{this.state.orderDeliveryAddress}</h2>
                    </div>
                  ) : (
                    <div className="awesome_del">
                      <h2>{this.state.orderHandled}</h2>
                    </div>
                  )}

                  <p>
                    PRODUCT AVAILABILITY WILL BE FILTERED BASED ON YOUR
                    SELECTION BELOW.
                  </p>

                  <div className="datetime_selt_sec">
                    <div className="datetime_selt_lbl">
                      {this.state.seletedAvilablityId === deliveryId
                        ? "Select Your Delivery Date & Time"
                        : "Choose Self Collection Date & Time"}
                    </div>

                    {!currenturl.includes(isCheckout) && (
                      <div>
                        {advancedTimeslotEnable === "1" ? (
                          <OrderAdvancedDatetimeSlot
                            {...this.props}
                            hdrState={this.state}
                            setdateTimeFlg={this.setdateTimeFlg}
                          />
                        ) : (
                          <OrderdatetimeSlot
                            {...this.props}
                            hdrState={this.state}
                            setdateTimeFlg={this.setdateTimeFlg}
                          />
                        )}
                      </div>
                    )}

                    <div className="ordrdatetime_error"></div>
                  </div>

                  <div className="btn_sec">
                    <input
                      type="button"
                      onClick={this.setOrderOutletDateTimeData.bind(this)}
                      className="button"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* success popup - end */}

          {/* success popup - Start */}
          <div
            id="awesome-popup-old"
            className="white-popup mfp-hide awesome_popup"
          >
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title poptt_icontop text-center">
                    <img src={iconWin} />
                    <h2 className="text-uppercase">Awesome</h2>
                    <small>We can deliver to you!</small>
                  </div>
                  <div className="awesome_del">
                    <h5>Your Delivery Address :</h5>
                    <h2>{this.state.orderDeliveryAddress}</h2>
                  </div>
                  <div className="btn_sec">
                    <input
                      type="button"
                      onClick={this.gotoProducts.bind(this)}
                      className="button"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* success popup - end */}

          {/* error Popup - start */}
          <div
            id="outlet-error-popup"
            className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_body">
                    <img className="warning-popup-img" src={warningImg} />
                    <h2 className="text-uppercase">Sorry</h2>
                    <p>
                      Self Collection is not available at this outlet for the
                      selected date and time.
                    </p>
                    <p>Please select a different outlet.</p>
                    <div className="alt_btns">
                      {this.state.seletedAvilablityId === pickupId ? (
                        <a
                          href="/"
                          onClick={this.gobckPkupOutletpopup.bind(this)}
                          className="button button-right popup-modal-dismiss disbl_href_action"
                        >
                          change outlet
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.gobckOutletpopup.bind(this)}
                          className="button button-right popup-modal-dismiss disbl_href_action"
                        >
                          change address
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* error Popup - end */}

          {/* Takeaway Popup - Start */}
          <div
            id="takeaway-popup"
            className="white-popup mfp-hide popup_sec self_popup"
          >
            <div className="order-body">
              <div className="self_popup_hea_row">
                <div className="self_popup_hea_col_left">
                  <img src={takeawayWtImg} />
                </div>
                <div className="self_popup_hea_col">
                  <h2>Please Select</h2>
                  <p>Your Self Collection Outlet</p>
                </div>
              </div>

              <div className="self_pop_row">
                <div className="self_pop_col self_pop_col_right">
                  <div className="self_pop_item">
                    <div className="self_pop_locbx">
                      {/*<h4>Search Cedele Outlet.</h4>*/}
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Search Outlet</label>
                          <input
                            type="input"
                            className="form-control input-focus"
                            onKeyUp={this.handleKeyPress}
                          />
                          <div className="outlet_error"></div>
                        </div>
                      </div>
                    </div>

                    <div className="self_outlet">
                      <h2>Select Outlets</h2>
                      <ul className="self_outlet_inner">
                        {this.loadOutletsList()}
                      </ul>
                      <a
                        className="button takeaway-btn-act disbl_href_action"
                        id="takeaway-continue-link"
                        href="/"
                        onClick={this.selectDatetm.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Takeaway Popup - end */}

          {/*  Coming Soon Popup - start */}
          <div
            id="comingsoon-popup"
            className="white-popup mfp-hide popup_sec comingsoon_popup"
          >
            <div className="order-body">
              <div className="comingsoon_popup_hea_row">
                <div className="comingsoon_popup_hea_col">
                  <h2>COMING SOON.</h2>
                </div>
              </div>
              <div className="comingsoon_pop_row">
                <p> can you select another availability.</p>
                <a
                  href="/"
                  onClick={this.closepopup.bind(this)}
                  className="button disbl_href_action"
                  title="Go Back"
                >
                  Go Back
                </a>
              </div>
            </div>
          </div>
          {/* Coming Soon Popup - end */}

          {/* Warning Popup - start */}
          <div
            id="warning-popup"
            className="white-popup mfp-hide popup_sec warning_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header">Warning</div>
                  <div className="alert_body">
                    <img className="warning-popup-img" src={warningplus} />
                    <p>By switching you are about to clear your cart.</p>
                    <p>Do you wish to proceed ?</p>
                    <div className="alt_btns">
                      <a
                        href="/"
                        className="popup-modal-dismiss button button-left disbl_href_action"
                      >
                        No
                      </a>
                      <a
                        href="/"
                        onClick={this.changeAvailability.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        Yes
                      </a>
                      <input type="hidden" id="warning_avilablity" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Warning Popup - end */}

          <div className="mbl-menu-overly"></div>
        </header>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    staticblack: blacksArr,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    /*  getGoogleLoginData: (formPayload) => {
      dispatch({ type: GET_GOOGLELOGINDATA, formPayload });
    }, */
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
