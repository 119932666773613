/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { apiUrl, appId, pickupId, mediaUrl } from "../Helpers/Config";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import innerbanner from "../../common/images/location-inner-banner.jpg";
import listmap from "../../common/images/list-map.png";
import listphone from "../../common/images/list-phone.png";
import listclock from "../../common/images/list-clock.png";
var Parser = require("html-react-parser");
import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
      outletList: "",
      outletRightSide: "",
      outletCategory: [],
      filteredOutlet: [],
      selectedcategories: "",
      outletName: "",
      outlet_menu_pdf: "",
    };
    this.loadOutletCategories();
  }

  componentDidMount() {
    this.props.getAllOutlets(pickupId);
    this.props.getGlobalSettings();
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState(
          {
            outlets: nextProps.outlets[0].result_set,
            filteredOutlet: nextProps.outlets[0].result_set,
          },
          function () {
            var currentthis = this;
            setTimeout(function () {
              google.maps.event.addDomListener(
                window,
                "load",
                currentthis.initMap()
              );
            }, 1000);

            this.getOutletData(nextProps.outlets[0].result_set);
          }
        );
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  loadOutletCategories() {
    axios
      .get(apiUrl + "outlets/getOutletCategories?app_id=" + appId)
      .then((categories) => {
        if (categories.data.status === "ok") {
          this.setState({ outletCategory: categories.data.result_set });
        }
      });
  }

  callPhoneOptn(phoneTxt) {
    return "";
    var resultTxt = "";
    if (phoneTxt !== "" && phoneTxt !== null) {
      if (phoneTxt.indexOf("+65") !== -1) {
        resultTxt = "tel:" + phoneTxt;
      } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
        resultTxt = "tel:+" + phoneTxt;
      } else {
        resultTxt = "tel:+65" + phoneTxt;
      }
    } else {
      resultTxt = "javascript:void(0);";
    }

    return resultTxt;
  }
  initMap() {
    var markers = [];
    var infoWindowContent = [];
    this.state.filteredOutlet.map((item) => {
      if (
        item.outlet_marker_latitude !== "" &&
        item.outlet_marker_longitude !== "" &&
        item.outlet_slug === this.props.match.params.locationSlug
      ) {
        var address = item.outlet_address_line1;
        address +=
          item.outlet_unit_number2 !== ""
            ? "#" + item.outlet_unit_number1 + "-" + item.outlet_unit_number2
            : item.outlet_unit_number1;
        address + "Singapore, " + item.outlet_postal_code;
        var phone = "";
        if (item.outlet_phone !== "") {
          phone =
            "<div class='location-map-phone'>" + item.outlet_phone + "</div>";
        }

        markers.push([
          item.outlet_name,
          item.outlet_marker_latitude,
          item.outlet_marker_longitude,
        ]);
        var pdfMenu = "";
        if (item.outlet_menu_pdf !== "" && item.outlet_menu_pdf !== null) {
          pdfMenu =
            "<a href='" +
            mediaUrl +
            "outlet/" +
            item.outlet_menu_pdf +
            "' target='_blank'>Menu</a>";
        }
        var imageURL =
          "https://www.cedelemarket.com/wp-content/uploads/2020/10/RF2-600x337.jpg";
        if (item.outlet_image !== "" && item.outlet_image !== null) {
          imageURL = mediaUrl + "outlet/" + item.outlet_image;
        }

        infoWindowContent.push([
          '<div class="store-card overlay">' +
            "<div class='location-map-img'><img src='" +
            imageURL +
            "' /></div>" +
            '<div class="store-content">' +
            "<div class='location-map-title'><h4><span>" +
            item.outlet_name +
            "</span>" +
            pdfMenu +
            "</h4></div>" +
            "<div class='location-map-address'>" +
            address +
            "</div>" +
            phone +
            "</div></div>",
        ]);
      }
    });
    /* if (
      markerID !== "" &&
      typeof markerID !== undefined &&
      typeof markerID !== "undefined"
    ) {
      console.log(markers[markerID]);
      google.maps.event.trigger(markers[markerID], "click");
      return false;
    } */
    var map;
    var bounds = new google.maps.LatLngBounds();
    var mapOptions = {
      mapTypeId: "roadmap",
    };

    // Display a map on the web page
    map = new google.maps.Map(document.getElementById("mapCanvas"), mapOptions);
    map.setTilt(50);

    // Add multiple markers to map
    var infoWindow = new google.maps.InfoWindow(),
      marker,
      i;

    // Place each marker on the map
    var clickhandles = document.getElementsByClassName("js-click-store");
    for (i = 0; i < markers.length; i++) {
      var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
      bounds.extend(position);
      marker = new google.maps.Marker({
        position: position,
        map: map,
        title: markers[i][0],
        /*   className: "mapid" + i, */
      });

      // marker.set("id", "mapid" + i);

      clickhandles[i].addEventListener(
        "click",
        (function (marker, i) {
          return function () {
            //infoWindow.setContent(infoWindowContent[i][0]);
            //infoWindow.open(map, marker);
          };
        })(marker, i),
        false
      );

      // Add info window to marker
      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          return function () {
            infoWindow.setContent(infoWindowContent[i][0]);
            infoWindow.open(map, marker);
          };
        })(marker, i)
      );

      // Center the map to fit all markers on the screen
      map.fitBounds(bounds);
      google.maps.event.addListener(map, "click", function (event) {
        // infoWindow.close();
      });
    }

    // Set zoom level
    var boundsListener = google.maps.event.addListener(
      map,
      "bounds_changed",
      function (event) {
        this.setZoom(13);
        google.maps.event.removeListener(boundsListener);
      }
    );
  }

  triggerClick(i) {
    $('div[aria-label="' + i + '"]').trigger("click");
  }

  getOutletData = (dataProp) => {
    var outletList = "";
    var outletRightSide = "";
    var outletCategory = "";
    var outletName = "";
    var outlet_menu_pdf = "";
    if (dataProp) {
      outletList = dataProp.map((item, index) => {
        if (
          item.outlet_marker_latitude !== "" &&
          item.outlet_marker_longitude !== "" &&
          item.outlet_slug === this.props.match.params.locationSlug
        ) {
          outletName = item.outlet_name;
          outlet_menu_pdf = item.outlet_menu_pdf;
          if (item.gallery.length > 0) {
            return item.gallery.map((image, item1) => {
              return (
                <li key={item1} className="js-click-store">
                  <img
                    src={
                      item.outlet_image !== ""
                        ? mediaUrl + "outlet/" + image.outlet_gallery_image
                        : ""
                    }
                  />
                </li>
              );
            });
          } else {
            return (
              <li key={index} className="js-click-store">
                <img
                  src={
                    item.outlet_image !== ""
                      ? mediaUrl + "outlet/" + item.outlet_image
                      : ""
                  }
                />
              </li>
            );
          }
        }
      });
      outletRightSide = dataProp.map((item, index) => {
        if (
          item.outlet_marker_latitude !== "" &&
          item.outlet_marker_longitude !== "" &&
          item.outlet_slug === this.props.match.params.locationSlug
        ) {
          if (this.state.outletCategory.length > 0) {
            this.state.outletCategory.map((item1) => {
              if (item1.outlet_category_id === item.outlet_category_id) {
                outletCategory = item1.outlet_category_title;
              }
            });
          }

          return (
            <div className="outlet-details-m" key={index}>
              <ul>
                <li>
                  <h5>{outletCategory}</h5>{" "}
                </li>
                <li>
                  <img className="listmap" src={listmap} />
                  <span>
                    {item.outlet_address_line1}{" "}
                    {item.outlet_unit_number2 !== ""
                      ? "#" +
                        item.outlet_unit_number1 +
                        "-" +
                        item.outlet_unit_number2
                      : item.outlet_unit_number1}{" "}
                    Singapore, {item.outlet_postal_code}
                  </span>
                </li>
                {item.outlet_phone !== "" && (
                  <li>
                    <img className="listmap" src={listphone} />
                    <a href={"tel:" + item.outlet_phone}>
                      {item.outlet_phone}
                    </a>{" "}
                  </li>
                )}
                {item.outlet_informations !== "" &&
                item.outlet_informations !== null ? (
                  <li>
                    <img className="listmap" src={listclock} />
                    <span>{Parser(item.outlet_informations)}</span>
                  </li>
                ) : (
                  item.da_monday_start_time !== "" &&
                  item.da_monday_start_time !== null &&
                  item.da_monday_end_time !== "" &&
                  item.da_monday_end_time !== null && (
                    <li>
                      <img className="listmap" src={listclock} />
                      <span>
                        Mon - Sun: {timeToConv12(item.da_monday_start_time)} to{" "}
                        {timeToConv12(item.da_monday_end_time)}{" "}
                      </span>
                    </li>
                  )
                )}
              </ul>
            </div>
          );
        }
      });
    }
    this.setState(
      {
        outletList: outletList,
        outletRightSide: outletRightSide,
        outletName: outletName,
        outlet_menu_pdf: outlet_menu_pdf,
      },
      function () {
        this.initMap();
      }
    );
  };

  handleChange = (event) => {
    this.setState({ selectedcategories: event.target.value }, function () {
      var currentOutlet = [];
      this.state.outlets.map((item) => {
        var availFilter = 1;
        if (this.state.selectedcategories !== "") {
          if (item.outlet_category_id === this.state.selectedcategories) {
            availFilter = 1;
          } else {
            availFilter = 0;
          }
        }
        if (availFilter === 1) {
          currentOutlet.push(item);
        }
      });
      this.setState({ filteredOutlet: currentOutlet }, function () {
        this.getOutletData(currentOutlet);
      });
    });
  };

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={innerbanner} />
            <p>Our Locations</p>
          </div>
        </div>
        <div className="outlet-micro-title">
          <div className="container">
            <h3>{this.state.outletName}</h3>
            {/* this.state.outlet_menu_pdf !== "" && (
              <a
                href={mediaUrl + "outlet/" + this.state.outlet_menu_pdf}
                target="_blank"
              >
                Menu
              </a>
            ) */}
          </div>
        </div>
        <div className="locations-page location-micro">
          <div className="container">
            <div className="location-lhs">
              <div className="llhs-body">
                <ul>{this.state.outletList}</ul>
              </div>
            </div>
            <div className="location-rhs">
              {this.state.outletRightSide}
              <div
                id="mapCanvas"
                style={{ width: "100%", height: "550px" }}
              ></div>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
