/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import Slider from "react-slick";

var settingsGallery = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedNavigation: "",
    };
  }

  componentWillReceiveProps(pdtProps) {
    if (
      this.state.selectedNavigation !== pdtProps.productState.selectedNavigation
    ) {
      this.setState({
        selectedNavigation: pdtProps.productState.selectedNavigation,
      });
    }
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.state.selectedNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <li
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/menu/category/" + loaddata.pro_cate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
              <ul>
                {this.loadSubcategory(
                  loaddata.pro_cate_slug,
                  loaddata.subcat_list_arr
                )}
              </ul>
            </li>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <li
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/"}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </li>
          );
        }
      });
      return mainMenu;
    } else {
      return;
    }
  }

  loadSubcategory(pro_cate_slug, subcat_list_arr) {
    if (
      subcat_list_arr.sub_result_set !== "" &&
      typeof subcat_list_arr.sub_result_set !== "undefined" &&
      typeof subcat_list_arr.sub_result_set !== undefined
    ) {
      return subcat_list_arr.sub_result_set.map((item, index) => {
        return (
          <li key={index}>
            <Link to={"/menu/" + pro_cate_slug + "/" + item.pro_subcate_slug}>
              {item.pro_subcate_name}
            </Link>
          </li>
        );
      });
    }
  }

  render() {
    return (
      <>
        <section className="catgegory-navigation mobile-catgegory-nav">
          <Slider {...settingsGallery}>{this.navigateMenuList()}</Slider>
        </section>
        <div className="menu-section-left">
          <div className="menu-section-left-inner product-menu">
            <ul>{this.navigateMenuList()}</ul>
          </div>
        </div>
      </>
    );
  }
}

export default MenuNavigation;
