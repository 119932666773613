/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { stripslashes, timeToConv12 } from "../Helpers/SettingHelper";

import { apiUrl, appId, pickupId, mediaUrl, baseUrl } from "../Helpers/Config";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import listmap from "../../common/images/list-map.png";
import listphone from "../../common/images/list-phone.png";
import listclock from "../../common/images/list-clock.png";
import innerbanner from "../../common/images/location-inner-banner.jpg";
import bakerycafe from "../../common/images/bakerycafe.png";
import bakerykitchen from "../../common/images/bakerykitchen.png";
import alldaydining from "../../common/images/alldaydining.png";
var Parser = require("html-react-parser");
import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
      outletList: "",
      outletCategory: [],
      filteredOutlet: [],
      selectedcategories: "",
    };
    /* const { isLoaded } = useLoadScript({
      googleMapsApiKey: "AIzaSyDoccflXxPTaV1qgHr1KN_VmRRdWAmUUSI", // Add your API key
    }); */
    this.loadOutletCategories();
  }

  componentDidMount() {
    this.props.getAllOutlets(pickupId);
    this.props.getGlobalSettings();
    /* const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDoccflXxPTaV1qgHr1KN_VmRRdWAmUUSI";
    script.async = true;
    document.body.appendChild(script); */
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState(
          {
            outlets: nextProps.outlets[0].result_set,
            filteredOutlet: nextProps.outlets[0].result_set,
          },
          function () {
            var currentthis = this;
            setTimeout(function () {
              google.maps.event.addDomListener(
                window,
                "load",
                currentthis.initMap()
              );
            }, 1000);

            this.getOutletData(nextProps.outlets[0].result_set);
          }
        );
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  loadOutletCategories() {
    axios
      .get(apiUrl + "outlets/getOutletCategories?app_id=" + appId)
      .then((categories) => {
        if (categories.data.status === "ok") {
          this.setState({ outletCategory: categories.data.result_set });
        }
      });
  }

  callPhoneOptn(phoneTxt) {
    return "";
    var resultTxt = "";
    if (phoneTxt !== "" && phoneTxt !== null) {
      if (phoneTxt.indexOf("+65") !== -1) {
        resultTxt = "tel:" + phoneTxt;
      } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
        resultTxt = "tel:+" + phoneTxt;
      } else {
        resultTxt = "tel:+65" + phoneTxt;
      }
    } else {
      resultTxt = "javascript:void(0);";
    }

    return resultTxt;
  }
  initMap() {
    var markers = [];
    var infoWindowContent = [];
    this.state.filteredOutlet.map((item) => {
      if (
        item.outlet_marker_latitude !== "" &&
        item.outlet_marker_longitude !== ""
      ) {
        var address = item.outlet_address_line1;
        address +=
          item.outlet_unit_number2 !== ""
            ? "#" + item.outlet_unit_number1 + "-" + item.outlet_unit_number2
            : item.outlet_unit_number1;
        address + "Singapore, " + item.outlet_postal_code;
        var phone = "";
        if (item.outlet_phone !== "") {
          phone =
            "<div class='location-map-phone'>" + item.outlet_phone + "</div>";
        }

        markers.push([
          item.outlet_name,
          item.outlet_marker_latitude,
          item.outlet_marker_longitude,
        ]);
        console.log(item, "itemitemitemitem");
        var pdfMenu = "<div>";
        if (item.outlet_menu_pdf !== "" && item.outlet_menu_pdf !== null) {
          pdfMenu +=
            "<a href='" +
            mediaUrl +
            "outlet/" +
            item.outlet_menu_pdf +
            "' target='_blank'>Menu</a> | ";
        }

        pdfMenu +=
          '<a href="' +
          baseUrl +
          "locations/" +
          item.outlet_slug +
          '">More</a></div>';

        var imageURL =
          "https://www.cedelemarket.com/wp-content/uploads/2020/10/RF2-600x337.jpg";
        if (item.outlet_image !== "" && item.outlet_image !== null) {
          imageURL = mediaUrl + "outlet/" + item.outlet_image;
        }

        infoWindowContent.push([
          '<div class="store-card overlay">' +
            "<div class='location-map-img'><img src='" +
            imageURL +
            "' /></div>" +
            '<div class="store-content">' +
            "<div class='location-map-title'><h4><span>" +
            item.outlet_name +
            "</span>" +
            pdfMenu +
            "</h4></div>" +
            "<div class='location-map-address'>" +
            address +
            "</div>" +
            phone +
            "</div></div>",
        ]);
      }
    });
    /* if (
      markerID !== "" &&
      typeof markerID !== undefined &&
      typeof markerID !== "undefined"
    ) {
      console.log(markers[markerID]);
      google.maps.event.trigger(markers[markerID], "click");
      return false;
    } */
    var map;
    var bounds = new google.maps.LatLngBounds();
    var mapOptions = {
      mapTypeId: "roadmap",
    };

    // Display a map on the web page
    map = new google.maps.Map(document.getElementById("mapCanvas"), mapOptions);
    map.setTilt(50);

    // Add multiple markers to map
    var infoWindow = new google.maps.InfoWindow(),
      marker,
      i;

    // Place each marker on the map
    var clickhandles = document.getElementsByClassName("js-click-store");
    for (i = 0; i < markers.length; i++) {
      var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
      bounds.extend(position);
      marker = new google.maps.Marker({
        position: position,
        map: map,
        title: markers[i][0],
        /*   className: "mapid" + i, */
      });

      // marker.set("id", "mapid" + i);

      clickhandles[i].addEventListener(
        "click",
        (function (marker, i) {
          return function () {
            console.log(i, "AAAAAA");
            infoWindow.setContent(infoWindowContent[i][0]);
            infoWindow.open(map, marker);
          };
        })(marker, i),
        false
      );

      // Add info window to marker
      google.maps.event.addListener(
        marker,
        "click",
        (function (marker, i) {
          return function () {
            infoWindow.setContent(infoWindowContent[i][0]);
            infoWindow.open(map, marker);
          };
        })(marker, i)
      );

      // Center the map to fit all markers on the screen
      map.fitBounds(bounds);
      google.maps.event.addListener(map, "click", function (event) {
        // infoWindow.close();
      });
    }

    // Set zoom level
    var boundsListener = google.maps.event.addListener(
      map,
      "bounds_changed",
      function (event) {
        this.setZoom(13);
        google.maps.event.removeListener(boundsListener);
      }
    );
  }

  triggerClick(i) {
    $('div[aria-label="' + i + '"]').trigger("click");
    //  this.initMap(i);
    /*  google.maps.event.trigger(gmarkers["Location 1"], "click");
    alert($("#gmimap" + parseInt(i) + 1).length);
    $("#gmimap" + parseInt(i) + 1).trigger("click"); */
    // google.maps.event.trigger(markers[i], "click");
  }

  getOutletData = (dataProp) => {
    var outletList = "";
    if (dataProp) {
      outletList = dataProp.map((item, index) => {
        if (
          item.outlet_marker_latitude !== "" &&
          item.outlet_marker_longitude !== ""
        ) {
          return (
            <li
              key={index}
              onClick={this.triggerClick.bind(this, item.outlet_name)}
              className="js-click-store"
            >
              <ul>
                <li>
                  <h5>{stripslashes(item.outlet_name).toUpperCase()}</h5>{" "}
                </li>
                <li>
                  <img className="listmap" src={listmap} />
                  <span>
                    {item.outlet_address_line1}{" "}
                    {item.outlet_unit_number2 !== ""
                      ? "#" +
                        item.outlet_unit_number1 +
                        "-" +
                        item.outlet_unit_number2
                      : item.outlet_unit_number1}{" "}
                    Singapore, {item.outlet_postal_code}
                  </span>
                </li>
                {item.outlet_phone !== "" && (
                  <li>
                    <img className="listmap" src={listphone} />
                    <a href={"tel:" + item.outlet_phone}>
                      {item.outlet_phone}
                    </a>{" "}
                  </li>
                )}
                {item.outlet_informations !== "" &&
                item.outlet_informations !== null ? (
                  <li>
                    <img className="listmap" src={listclock} />
                    <span>{Parser(item.outlet_informations)}</span>
                  </li>
                ) : (
                  item.da_monday_start_time !== "" &&
                  item.da_monday_start_time !== null &&
                  item.da_monday_end_time !== "" &&
                  item.da_monday_end_time !== null && (
                    <li>
                      <img className="listmap" src={listclock} />
                      <span>
                        Mon - Sun: {timeToConv12(item.da_monday_start_time)} to{" "}
                        {timeToConv12(item.da_monday_end_time)}{" "}
                        {/* <br /> (Last Order: 07:00
                    PM) */}
                      </span>
                    </li>
                  )
                )}
              </ul>
            </li>
          );
        }
      });
    }
    this.setState({ outletList: outletList }, function () {
      this.initMap();
    });
  };

  handleChange = (event) => {
    this.setState({ selectedcategories: event.target.value }, function () {
      var currentOutlet = [];
      this.state.outlets.map((item) => {
        var availFilter = 1;
        if (this.state.selectedcategories !== "") {
          if (item.outlet_category_id === this.state.selectedcategories) {
            availFilter = 1;
          } else {
            availFilter = 0;
          }
        }
        if (availFilter === 1) {
          currentOutlet.push(item);
        }
      });
      this.setState({ filteredOutlet: currentOutlet }, function () {
        this.getOutletData(currentOutlet);
      });
    });
  };

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={innerbanner} />
            <p>Our Locations</p>
          </div>
        </div>

        <div className="locations-top">
          <div className="container">
            <ul>
              <li>
                <div className="lt-top-img">
                  <img src={bakerycafe} />
                </div>
                <div className="lt-top-desc">
                  <h4>
                    <a href="#">Cedele Bakery Cafe</a>
                  </h4>
                  <span>Quick Service Concept</span>
                  <p>
                    Without fail, we do it fresh; out from the oven and to our
                    stores every morning. With our all day segment of organic
                    offerings of artisanal sandwiches, design-your-own salads,
                    and slurp-worthy soups; our quick-service Bakery Cafes serve
                    up a range of healthy fare all day throughout breakfast,
                    lunch and dinner.
                  </p>
                </div>
              </li>
              <li>
                <div className="lt-top-img">
                  <img src={bakerykitchen} />
                </div>
                <div className="lt-top-desc">
                  <h4>
                    <a href="#">Cedele Bakery Kitchen</a>
                  </h4>
                  <span>Fast Casual Dining</span>
                  <p>
                    Creatively combined to cater to your preference, our Bakery
                    Kitchens fuse the convenience of counter service with
                    restaurant dine-in options. Cooked-to-order straight from
                    our in-house kitchens, we offer a compact menu with a choice
                    of whole-day breakfast, lunch, and dinner dishes. Or simply
                    grab a freshly made snack from our bakery takeaway section;
                    best for the busy bee!
                  </p>
                </div>
              </li>
              <li>
                <div className="lt-top-img">
                  <img src={alldaydining} />
                </div>
                <div className="lt-top-desc">
                  <h4>
                    <a href="#">Cedele All Day Dining</a>
                  </h4>
                  <span>Full Service Restaurant</span>
                  <p>
                    Indulge yourself in casual contemporary dining bliss at our
                    All-Day Dining restaurants, offering friendly table service
                    that complements with our delicious Insta-worthy whole-day
                    breakfast, lunch, and dinner menus. Relish in the relaxing
                    atmosphere and appetising ambience, and you’ll quickly find
                    yourself spoilt for choice!
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="locations-page">
          <div className="container">
            <div className="location-lhs">
              <div className="llhs-title">
                <span>Filter By</span>

                <select onChange={this.handleChange}>
                  <option value="">Select Categories</option>
                  {this.state.outletCategory.length > 0 &&
                    this.state.outletCategory.map((item, index) => {
                      return (
                        <option value={item.outlet_category_id} key={index}>
                          {item.outlet_category_title}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="llhs-body">
                <ul>{this.state.outletList}</ul>
              </div>
            </div>
            <div className="location-rhs">
              <div
                id="mapCanvas"
                style={{ width: "100%", height: "780px" }}
              ></div>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
