/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { projectTitle, appId, apiUrl } from "../Helpers/Config";
import { validated } from "react-custom-validation";
import cookie from "react-cookies";
import axios from "axios";
import Select from "react-select";
import validator from "validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import country from "../../common/js/country.json";
var Parser = require("html-react-parser");
var countryList = [];
var phoneCodeList = [];
country.forEach(function (element) {
  countryList.push({ label: element.name, value: element.name });
  phoneCodeList.push({
    label: element.short_code + " | " + element.dial_code,
    value: element.dial_code,
  });
});
const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";
const minLength = (password, length) =>
  password.length >= length ? null : "Password must be at least 6 characters.";
const areSame = (password, rePassword) =>
  password === rePassword ? null : "Password do not match.";
const isChecked = (terms) =>
  terms ? null : "Please accept terms and conditions";
function validationConfigSignup(props) {
  const {
    firstname,
    email,
    password,
    rePassword,
    mobile,
    gender,
    nationality,
    referrercode,
    terms,
  } = props.fields;

  return {
    fields: [
      "firstname",
      "email",
      "password",
      "rePassword",
      "mobile",
      "gender",
      "nationality",
      "terms",
    ],

    validations: {
      firstname: [[isEmpty, firstname]],
      email: [
        [isEmail, email],
        [isUnique, email],
      ],
      password: [[minLength, password, 6]],
      rePassword: {
        rules: [[areSame, password, rePassword]],
        fields: ["password", "rePassword"],
      },
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
        [isPhonenumerExist, mobile],
      ],
      gender: [[isEmpty, gender]],
      nationality: [[isEmpty, nationality]],
      terms: [[isChecked, terms, 1]],
    },
  };
}

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Mobile Number.";

const isUnique = (email) => {
  var customerId =
    typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
  if (customerId === "") {
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_email: email,
    };

    axios
      .post(apiUrl + "customer/email_exist", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "ok") {
          document.getElementById("spn-email-error").innerHTML = "";
        } else {
          document.getElementById("spn-email-error").innerHTML =
            '<span class="error">Email already exists</span>';
        }
      });
  }
};

const isPhonenumerExist = (mobile) => {
  var customerId =
    typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
  if (customerId === "") {
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_phone: mobile,
    };
    axios
      .post(apiUrl + "customer/phoneno_exist", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "ok") {
          document.getElementById("spn-mobile-error").innerHTML = "";
        } else {
          document.getElementById("spn-mobile-error").innerHTML =
            '<span class="error">Mobile number already exists</span>';
        }
      });
  }
};

class Membersignup extends Component {
  constructor(props) {
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);
    super(props);
    this.state = {
      Maxdate: Maxdate,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  closepopup(path) {
    const { history } = this.props;
    history.push(path);
    $.magnificPopup.close();
  }
  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "dd/MM/yyyy");
    this.setState({ birthdate: datevalue });
    this.props.onChange("birthdate", datevalue);
  }
  opentMemberLogin() {
    this.props.sateValChange("open-member-login", "Yes");
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgFirstName,
      errMsgEmail,
      errMsgPassword,
      errMsgrePassword,
      errMsgMobile,
      errMsgGender,
      errMsgNationality,
      errMsgTerms;

    if (
      $validation.firstname.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if (
      $validation.email.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if (
      $validation.password.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }
    if (
      $validation.rePassword.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgrePassword = $validation.rePassword.show && (
        <span className="error">{$validation.rePassword.error.reason}</span>
      );
    }
    if (
      $validation.mobile.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }
    if (
      $validation.gender.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgGender = $validation.gender.show && (
        <span className="error">{$validation.gender.error.reason}</span>
      );
    }
    if (
      $validation.nationality.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgNationality = $validation.nationality.show && (
        <span className="error">{$validation.nationality.error.reason}</span>
      );
    }
    if (
      $validation.terms.error.reason !== undefined &&
      fields.completeReg === "N"
    ) {
      errMsgTerms = $validation.terms.show && (
        <span className="error">{$validation.terms.error.reason}</span>
      );
    }

    var birthdate = new Date();
    if (this.state.birthdate !== "") {
      birthdate = this.state.birthdate;
    } else if (
      fields.birthdate !== "" &&
      fields.birthdate !== "0000-00-00" &&
      fields.birthdate !== "00/00/0000"
    ) {
      birthdate = new Date(fields.birthdate);
    }

    return (
      <div className="popup-body memberlogup-popup-body">
        <div className="form-group">
          <div
            className={
              fields.firstname !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="text"
              placeholder="Name"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.firstname}
              {...$field("firstname", (e) =>
                onChange("firstname", e.target.value)
              )}
            />
            {errMsgFirstName}
          </div>
        </div>
        <div className="form-group">
          <div
            className={
              fields.password !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgPassword}
          </div>
        </div>
        <div className="form-group">
          <div
            className={
              fields.rePassword !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="password"
              placeholder="Confirm Password"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.rePassword}
              {...$field("rePassword", (e) =>
                onChange("rePassword", e.target.value)
              )}
            />
            {errMsgrePassword}
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.email !== "" ? "focus-out focused" : "focus-out"}
          >
            <input
              type="text"
              placeholder="Email"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            <div id="spn-email-error">{errMsgEmail}</div>
          </div>
        </div>

        <div className="member-updates-msg">
          Note: Your password for Cedele.com and Cedele Reward$ Membership may
          differ. By signing up as a Cedele Reward$ Member, you also agree to
          receive important membership updates, member promotions and account
          information via email.
        </div>

        <div className="form-group">
          <div className="re_select">
            {
              /* fields.gender !== "" && ( */
              <Select
                placeholder="Gender"
                onChange={onChange.bind(this, "gender")}
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  {
                    value: "O",
                    label: "Unspecified",
                  },
                ]}
              />
              /* ) */
            }
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.gender != "" ? "focus-out focused" : "focus-out"}
          >
            {/*  <label>Birthday</label> */}
            <div className="react_datepicker">
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Birthday"
                className="form-control"
                selected={birthdate}
                maxDate={this.state.Maxdate}
                dateFormat="dd/MM/yyyy"
                onChange={this.handleChangeDate}
              />

              {/* errMsgbirthdate */}
            </div>
          </div>
        </div>
        <div className="form-group">
          <div
            className={fields.mobile !== "" ? "focus-out focused" : "focus-out"}
          >
            <div className="seperate-mob">
              <Select
                placeholder="Code"
                onChange={onChange.bind(this, "mobilecountry")}
                defaultValue={[
                  {
                    label: "SG | +65",
                    value: "+65",
                  },
                ]}
                options={phoneCodeList}
              />
              <input
                type="tel"
                placeholder="Mobile Number"
                className="form-control input-focus"
                pattern="\d*"
                value={fields.mobile}
                {...$field("mobile", (e) => onChange("mobile", e.target.value))}
              />
            </div>
            <div id="spn-mobile-error">{errMsgMobile}</div>
          </div>
        </div>
        <div className="form-group">
          <div
            className={
              fields.firstname !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <Select
              placeholder="Nationality"
              onChange={onChange.bind(this, "nationality")}
              options={countryList}
            />
            {errMsgNationality}
          </div>
        </div>

        <div className="member-updates-msg">
          Cedele (managed by The Bakery Depot Pte Ltd) will not be held liable
          for any loss or damage arising as a result of members’ failure to
          provide a working email address; or non-receipt of email
          communications due to loss mail, spam filter or firewall facility in
          the member’s email set up
        </div>

        <div className="form-group" style={{ display: "none" }}>
          <div
            className={
              fields.referrercode !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="text"
              placeholder="Referrer Code"
              autoComplete="off"
              className="form-control input-focus"
              value={fields.referrercode}
              {...$field("referrercode", (e) =>
                onChange("referrercode", e.target.value)
              )}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="custom_checkbox">
            <input
              type="checkbox"
              id="memberterms"
              checked={fields.terms}
              {...$field(
                "terms",
                (e) => onChange("terms", e.target.value),
                null,
                false
              )}
            />{" "}
            <span></span>
            <div className="sign_reg">
              <p>
                I agree to the {projectTitle}{" "}
                <a
                  className="sign_reg_uline"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("/rewards", "_blank");
                  }}
                  href="/rewards"
                >
                  Reward$ Terms &amp; Conditions
                </a>
              </p>
            </div>
          </div>
          {errMsgTerms}
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              type="button"
              className="btn btn_black btn_minwid signup_submit member_signup_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="form-group-11">
          <div className="controls single-link">
            <a href="#login-popup" className="open-popup-link">
              GO TO CEDELE.COM LOGIN
            </a>{" "}
            |{" "}
            <a href={void 0} onClick={this.opentMemberLogin.bind(this)}>
              GO TO CEDELE REWARD$ LOGIN
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Membersignup = validated(validationConfigSignup)(Membersignup);

export default withRouter(Membersignup);
