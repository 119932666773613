/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
var qs = require("qs");
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";
var Parser = require("html-react-parser");
import { appId, apiUrl, deliveryId } from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showAlert,
} from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import Moment from "moment";

import { GET_ACTIVITYCOUNT } from "../../actions";

import cookie from "react-cookies";
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import clock from "../../common/images/clock-round.svg";
import calender from "../../common/images/calenderorder.svg";
import innerbanner from "../../common/images/inner-banner.jpg";
class Rewardsfromcrm extends Component {
  constructor(props) {
    super(props);

    var acentisMemberid =
      typeof cookie.load("AcentisMemberid") === "undefined"
        ? ""
        : cookie.load("AcentisMemberid");
    acentisMemberid =
      acentisMemberid !== "null" && acentisMemberid !== null
        ? acentisMemberid
        : "";
    var ascentisCardNo =
      typeof cookie.load("AscentisCardNo") === "undefined"
        ? ""
        : cookie.load("AscentisCardNo");
    ascentisCardNo =
      ascentisCardNo !== "null" && ascentisCardNo !== null
        ? ascentisCardNo
        : "";

    this.state = {
      acentis_memberid: acentisMemberid,
      acentis_cardno: ascentisCardNo,
      earned_pointslist: [],
      redeemed_pointslist: [],
      acentis_member_data: [],
      reward_point_count: 0,
      currentTab: "C",
    };

    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", { path: "/" });
      props.history.push("/");
    }

    if (acentisMemberid !== "" && ascentisCardNo !== "") {
      this.getAcentisMemberPoints();
      this.getCrmRewards();
    }
  }

  componentDidMount() {
    $("#dvLoading").fadeOut(2000);
  }

  getAcentisMemberPoints() {
    var acentisMemberid = this.state.acentis_memberid;
    var postObject = {
      app_id: appId,
      member_id: acentisMemberid,
    };
    axios
      .post(apiUrl + "ascentiscrm/memberEnquiry", qs.stringify(postObject))
      .then((res) => {
        var rewardPointCount = 0;
        var acentisMemberData = Array();
        if (res.data.status === "ok") {
          var crmResulSet = res.data.result_set;
          if (Object.keys(crmResulSet).length > 0) {
            rewardPointCount =
              Object.keys(crmResulSet.CardLists).length > 0
                ? crmResulSet.CardLists[0].TotalPointsBAL
                : 0;
            rewardPointCount =
              rewardPointCount !== "" ? parseFloat(rewardPointCount) : 0;
            console.log("rewardPointCount", rewardPointCount);
          }
        }
        this.setState({
          reward_point_count: rewardPointCount,
          acentis_member_data: acentisMemberData,
        });
      });
  }

  getCrmRewards() {
    var acentisMemberid = this.state.acentis_memberid;
    var ascentisCardNo = this.state.acentis_cardno;

    var urlTxt =
      apiUrl +
      "ascentiscrm/membertranshistory?app_id=" +
      appId +
      "&crm_memberid=" +
      acentisMemberid +
      "&crm_cardno=" +
      ascentisCardNo;
    axios.get(urlTxt).then((res) => {
      var earnedPointsList = Array();
      var redeemedPointsList = Array();
      if (res.data.status === "ok") {
        var pointsResulSet = res.data.result_set;
        if (Object.keys(pointsResulSet).length > 0) {
          earnedPointsList = pointsResulSet.earned_points_list;
          redeemedPointsList = pointsResulSet.redeemed_points_list;
        }
      }

      this.setState({
        earned_pointslist: earnedPointsList,
        redeemed_pointslist: redeemedPointsList,
      });
    });
  }

  componentWillReceiveProps(nextProps) {}

  showReciptNo(recptNum) {
    var recptNumTxt = "";
    if (recptNum !== "") {
      var recptNumArr = recptNum.split("~");
      if (recptNumArr.length > 2) {
        recptNumTxt = recptNumArr[2];
      } else if (recptNumArr.length > 1) {
        recptNumTxt = recptNumArr[1];
      } else {
        recptNumTxt = recptNumArr[0];
      }
    }

    return recptNumTxt;
  }

  /* reward earned - start */
  __rewardListing() {
    if (Object.keys(this.state.earned_pointslist).length > 0) {
      return this.state.earned_pointslist.map((points, index) => (
        <div
          key={points.AutoID}
          className={"current_order custom_points_cls custom_crmpoints_cls"}
        >
          <div>
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER NO - {this.showReciptNo(points.ReceiptNo)}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>EARNED</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="delivery_total delivery_total_number">
                <div className="expiry_on_lhs">
                  <div className="delivery_total_left">
                    <h4 className="checkoutDate">
                      {Moment(points.TransactDate).format("DD/MM/YYYY")}
                    </h4>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <h4 className="checkoutTime">
                      {Moment(
                        points.TransactDate + " " + points.TransactTime
                      ).format("h:mm A")}
                    </h4>
                  </div>
                </div>
              </div>

              {/*<div className="order_amt_div">
                  <h3>
                    from dsfkhsdh snbvj
                  </h3>
				</div>*/}
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>
                    Order Amount : {parseFloat(points.OrderAmount).toFixed(2)}
                  </h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">{points.Points} Points</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }
  /* reward earned - end */

  /* reward redeem - start */

  __rewardRedeemListing() {
    if (Object.keys(this.state.redeemed_pointslist).length > 0) {
      return this.state.redeemed_pointslist.map((points, index) => (
        <div
          key={points.AutoID}
          className={"current_order custom_points_cls custom_crmpoints_cls"}
        >
          <div>
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER NO - {this.showReciptNo(points.ReceiptNo)}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>REDEEMED</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="delivery_total delivery_total_number">
                <div className="expiry_on_lhs">
                  <div className="delivery_total_left">
                    <h4 className="checkoutDate">
                      {Moment(points.TransactDate).format("DD/MM/YYYY")}
                    </h4>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <h4 className="checkoutTime">
                      {Moment(
                        points.TransactDate + " " + points.TransactTime
                      ).format("h:mm A")}
                    </h4>
                  </div>
                </div>
                <div className="expiry_on_lhs">
                  <div className="delivery_total_left">
                    <h4 className="checkoutDate"></h4>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <h4 className="checkoutTime" style={{ color: "red" }}>
                      Expires On -{" "}
                      {Moment(points.ExpiryDate).format("DD/MM/YYYY")}
                    </h4>
                  </div>
                </div>
              </div>

              {/*<div className="order_amt_div">
                  <h3>
                    from dsfkhsdh snbvj
                  </h3>
				</div>*/}
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  {/*<h3>Order Amount : {parseFloat(points.OrderAmount).toFixed(2)}</h3>*/}
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    {Math.abs(points.Points)} Points
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }
  /* reward redeem - end */

  openActiveTab(currentTab) {
    console.log(currentTab, "currentTabcurrentTab");
    this.setState({ currentTab: currentTab });
  }

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 2,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    /*setTimeout(function () {
		$('#dvLoading').remove();
		}, 500);*/
    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={innerbanner} />
            <p>My Account</p>
            <span>
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </span>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          {/* container div - start */}
          <div className="container">
            <div className="mainacc_menusec">
              <div className="mainacc_menuout">
                <ul className="mainacc_menulist">
                  <li>
                    <Link to="/myaccount" title="My Account">
                      <span>Account Details</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/myorders" title="My Orders">
                      <span>Orders</span>
                      {parseFloat(this.state.overall_orders) > 0 && (
                        <span id="masterCount">
                          {this.state.overall_orders}
                        </span>
                      )}
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/rewards" title="My Rewards">
                      <span>Rewards</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mypromotions" title="My Promotions">
                      <span>Promotions</span>
                    </Link>
                  </li>

                  {/*  <li>
                    <Link to="/voucher" title="Voucher">
                      <span>Voucher</span>
                    </Link>
                  </li> */}

                  <li>
                    <a
                      href="#change-password-popup"
                      className="open-popup-link"
                    >
                      <span>Change Password</span>
                    </a>
                  </li>
                  <li>
                    <a href="/logout">
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
                <div className="mbacc_mslidersec mbacc_mslider">
                  <Slider {...settingsMyAcc}>
                    <div className="mbacc_mslide">
                      <Link to="/myaccount" title="My Account">
                        <span>Account Details</span>
                      </Link>
                    </div>
                    <div className="mbacc_mslide">
                      <Link to="/myorders" title="My Orders">
                        <span>Orders</span>
                      </Link>
                    </div>
                    <div className="mbacc_mslide active">
                      <Link to="/myrewards" title="My Rewards">
                        <span>Rewards</span>
                      </Link>
                    </div>
                    <div className="mbacc_mslide">
                      <Link to="/mypromotions" title="My Promotions">
                        <span>Promotions</span>
                      </Link>
                    </div>
                    {/* <div className="mbacc_mslide">
                      <Link to="/voucher" title="My Voucher">
                        <span>Voucher</span>
                      </Link>
                    </div> */}
                  </Slider>
                </div>
              </div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="congrats">
                        <div className="congrats-inner">
                          <h3>Your Current Reward Points</h3>
                          <h2>
                            {this.state.reward_point_count.toFixed(2) || "0.00"}{" "}
                            Points
                          </h2>
                        </div>
                      </div>
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec">
                            <ul className="nav nav-tabs text-center">
                              <li
                                className={
                                  this.state.currentTab === "C" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "C")}
                                  aria-expanded="true"
                                >
                                  <span>REWARDS EARNED</span>
                                </a>
                              </li>
                              <li
                                className={
                                  this.state.currentTab === "P" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "P")}
                                  aria-expanded="false"
                                >
                                  <span>REWARDS REDEEMED</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              {this.state.currentTab === "C" && (
                                <div
                                  id="tab-id-inn5"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    REWARDS EARNED<i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="order-delivery">
                                      <div className="ord-body">
                                        <div className="cur-order-body reward-list-body">
                                          <div className="myacc_order_details">
                                            {this.__rewardListing()}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {this.state.currentTab === "P" && (
                                <div
                                  id="tab-id-inn6"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    REWARDS REDEEMED<i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="cur-order-body reward-list-body">
                                      <div className="myacc_order_details">
                                        {this.__rewardRedeemListing(
                                          this.state.pointsRedeemed
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container div - end */}
        </div>

        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};
Rewardsfromcrm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Rewardsfromcrm)
);
