/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId } from "../Helpers/Config";
var Parser = require("html-react-parser");
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Select from "react-select";
import { connect } from "react-redux";
import innerbanner from "../../common/images/ContactUs.png";
import {
  GET_PICKUP_OUTLETS,
  GET_CONTACTDATA,
  GET_CONTACTCONTENT,
  GET_GLOBAL_SETTINGS,
} from "../../actions";

var Parser = require("html-react-parser");

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outlet_loaded: "no",
      outlets_result: [],
      outlets: [],
      outlet_id: "",
      fields: {
        name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        message: "",
      },
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
    };
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      customer_first_name: formPayload.name,
      customer_emailaddress: formPayload.email,
      customer_phonenumber: formPayload.mobile,
      subject: formPayload.subject.value,
      customer_message: formPayload.message,
    };

    this.props.getContactData(qs.stringify(postObject));
  };

  componentDidMount() {
    this.props.getGlobalSettings();
    /*this.props.getPickupOutlets();*/
    /* $("html, body").animate({ scrollTop: 0 }, 800); */
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contactdata !== this.props.contactdata) {
      if (nextProps.contactdata[0].status === "ok") {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        /* setTimeout(function () {
          window.location.reload();
        }, 3000); */
      } else {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }

    /* $("html, body").animate({ scrollTop: 0 }, 800); */
  }

  sateValChangefn(outlvalue) {
    this.setState({ outlet_id: outlvalue });
  }

  getOutletData = () => {
    var outLetLst = this.state.outlets;
    var outletId = this.state.outlet_id;
    if (Object.keys(outLetLst).length > 0) {
      var listhtml = outLetLst.map((item, index) => (
        <div
          key={index}
          onClick={this.sateValChangefn.bind(this, item.outlet_id)}
          className={
            outletId === item.outlet_id ? "locate-lirow active" : "locate-lirow"
          }
        >
          <h4>{stripslashes(item.outlet_name)}</h4>
          <p>{item.outlet_address_line1}</p>
          <p>
            Phone: <span className="phone-show-dskp">{item.outlet_phone}</span>
            <span className="phone-show-mbl">
              {" "}
              <a href={this.callPhoneOptn(item.outlet_phone)}>
                {item.outlet_phone}
              </a>
            </span>
          </p>
        </div>
      ));
      return listhtml;
    } else {
      return "";
    }
  };

  contMapHtml = () => {
    var outletId = this.state.outlet_id;
    /* jankosoft 'Dempsey' => 190 */
    if (outletId === "218") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.784116066201!2d103.80677851426552!3d1.30458871208381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1a3cb9baec87%3A0x9afb0ed56701e15!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559310893750!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
    /* jankosoft 'Race Course' => 192 */
    if (outletId === "216") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.775596869837!2d103.84998271832654!3d1.3099511611600971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19c71f708091%3A0xcb1c05d04cdfa80f!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559311174739!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
    /* jankosoft 'Suntec' => 191 */
    if (outletId === "217") {
      return (
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7996915113254!2d103.85716761426546!3d1.294727262111923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a8a51c9f51%3A0x882bc15b9ef17943!2sMuthu&#39;s+Curry!5e0!3m2!1sen!2sin!4v1559311243416!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="contactusmain-top-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={innerbanner} />
            <p>Get In Touch</p>
          </div>
        </div>

        <div className="contact_wrap">
          <div className="contactus_wrapper">
            <div className="container">
              <Form
                fields={this.state.fields}
                onChange={this.fieldChange}
                onValid={this.handleFormSubmit}
                outletListData={this.getOutletData}
                contMapFun={this.contMapHtml}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        </div>

        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Phone number.";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

function validationConfig(props) {
  const { name, mobile, email, subject, message } = props.fields;

  return {
    fields: ["name", "mobile", "email", "subject", "message"],

    validations: {
      name: [[isEmpty, name]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      subject: [[isEmpty, subject]],
      message: [[isEmpty, message]],
    },
  };
}

class Form extends React.Component {
  state = {
    pageTitle: "",
    pageDesc: "",
  };

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgFirstName,
      errMsgEmail,
      errMsgMobile,
      errMsgSubject,
      errMsgMessage;

    let contactcontent = "";

    if (this.props.contactContent != undefined) {
      contactcontent = Parser(
        this.props.contactContent.result_set[0].staticblocks_description
      );
    }

    if ($validation.name.error.reason !== undefined) {
      document.getElementsByClassName("name").className = "error";
      errMsgFirstName = $validation.name.show && (
        <span className="error">{$validation.name.error.reason}</span>
      );
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.subject.error.reason !== undefined) {
      errMsgSubject = $validation.subject.show && (
        <span className="error">{$validation.subject.error.reason}</span>
      );
    }
    if ($validation.message.error.reason !== undefined) {
      errMsgMessage = $validation.message.show && (
        <span className="error">{$validation.message.error.reason}</span>
      );
    }

    return (
      <div className="white_bg contact-all">
        <div className="contact-all-container">
          {/* <div className="locate-map">					
  						<div className="locate-mapleft">
  							<div className="locate-list">
  								{this.props.outletListData()} 
  							</div>
  						</div>
  						<div className="locate-mapright">
  							<div id="locateGmap">
  								{this.props.contMapFun()}
  							</div>
  						</div>
  				</div> */}

          {/*  <div className="contact-box-left">
            <h3>contact us</h3>
            <ul>
              <li className="contact-about-order">
                <h5>Have a question about your order?</h5>
                <p>Send us an email or give us a call.</p>
              </li>

              <li>
                <h5>Advance orders:</h5>
                <p>
                  <a href="mailto:orders@cedeledepot.com">
                    orders@cedeledepot.com
                  </a>{" "}
                </p>
              </li>

              <li>
                <h5>Delivery/self-collection orders:</h5>
                <p>
                  <a href="https://wa.me/+6596458806" target="_blank">
                    +65 9645 8806
                  </a>{" "}
                  (WhatsApp Only)
                </p>
              </li>

              <li>
                <h5>Bulk/corporate orders above $500:</h5>
                <p>
                  <a href="https://wa.me/+6596458806" target="_blank">
                    +65 9645 8806
                  </a>{" "}
                  (WhatsApp Only)
                </p>
                <p>
                  <a href="tel:6569229717" target="_blank">
                    +65 6922 9717
                  </a>
                </p>
              </li>
            </ul>
          </div> */}

          <div className="contact_form contact_form-right">
            <h3>
              WE’D LOVE TO HEAR <strong>FROM</strong> YOU!
            </h3>
            <ul>
              <li>
                <p>
                  Have a general enquiry or some feedback you'd like to share?
                  <br />
                  Send us a message and we'll respond within 3 working days.
                </p>
              </li>
            </ul>

            <form className="form_sec clear">
              <div className="contact_col">
                <div className="form-group">
                  <div className="focus-out">
                    <label>Name*</label>
                    <input
                      type="input"
                      className="form-control input-focus"
                      value={fields.name}
                      maxLength="80"
                      {...$field("name", (e) =>
                        onChange("name", e.target.value)
                      )}
                    />
                    {errMsgFirstName}
                  </div>
                </div>

                <div className="col-50">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Contact Number*</label>
                      <input
                        type="text"
                        className="form-control input-focus"
                        value={fields.mobile}
                        maxLength="11"
                        {...$field("mobile", (e) =>
                          onChange("mobile", e.target.value)
                        )}
                      />
                      {errMsgMobile}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email*</label>
                      <input
                        type="input"
                        className="form-control input-focus"
                        value={fields.email}
                        maxLength="85"
                        {...$field("email", (e) =>
                          onChange("email", e.target.value)
                        )}
                      />
                      {errMsgEmail}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="focus-out">
                    <label>Subject*</label>
                    <div className="re_select">
                      <Select
                        placeholder="Please select one of the following subjects"
                        isSearchable={false}
                        onChange={onChange.bind(this, "subject")}
                        options={[
                          {
                            value: "General Enquiry",
                            label: "General Enquiry",
                          },
                          {
                            value: "Service/Product Feedback",
                            label: "Service/Product Feedback",
                          },
                          { value: "Order Enquiry", label: "Order Enquiry" },
                          {
                            value: "Cedele Rewards Member",
                            label: "Cedele Rewards Member",
                          },
                          {
                            value: "Corporate Enquiries (Orders above $1,000)",
                            label: "Corporate Enquiries (Orders above $1,000)",
                          },
                        ]}
                      />
                    </div>
                    {errMsgSubject}
                  </div>
                </div>

                <div className="form-group">
                  <div className="focus-out">
                    <label>Message*</label>

                    <textarea
                      className="form-control input-focus"
                      id="feedback"
                      {...$field("message", (e) =>
                        onChange("message", e.target.value)
                      )}
                    ></textarea>
                    {errMsgMessage}
                  </div>
                </div>
                <div className="btn_sec">
                  <button
                    type="button"
                    className="btn btn_black btn-lg btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.$submit(onValid, onInvalid);
                    }}
                  >
                    Submit<div className="ripple-container"></div>
                  </button>
                  <br />
                </div>
              </div>
              <div className="contact_col"></div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  return {
    outlets: state.outlets,
    globalsettings: state.settings,
    contactdata: state.contactdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
