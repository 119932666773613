/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import innerbanner from "../../common/images/inner-banner.jpg";
import shopslide from "../../common/images/shop-slide.jpg";
import shopslideone from "../../common/images/shop-slide1.jpg";
import shopmainslide from "../../common/images/shop-main-slider.jpg";
import shopmainslideone from "../../common/images/shop-main-slider1.jpg";
import welcomeslide from "../../common/images/welcome-slider.jpg";
import welcomeslide1 from "../../common/images/welcome-slider1.jpg";
import welcomeslide2 from "../../common/images/welcome-slider2.jpg";
import welcomeslide3 from "../../common/images/welcome-slider3.jpg";

import highlight from "../../common/images/highpart.png";
import highlight1 from "../../common/images/highpart1.png";
import highlight2 from "../../common/images/highpart2.png";
import highlight3 from "../../common/images/highpart3.png";

import FeaturedProducts from "../Home/HomeSubCompo/FeaturedProducts";
var settingsGallery = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
var settingspromoGallery = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
};
var settingswelcomGallery = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
};

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = { stayhomeblk: "" };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}
  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}
        <div className="shop-banner">
          <div className="container">
            <Slider {...settingsGallery}>
              <div>
                <img src={shopmainslide} alt="" />
                <div className="shp-content">
                  <h4>Sample Text Line Here</h4>
                  <h3>Sample Text Line Here</h3>
                  <a href="#" className="button">
                    Order Now
                  </a>
                </div>
              </div>
              <div>
                {" "}
                <img src={shopmainslideone} alt="" />
              </div>
              <div>
                {" "}
                <img src={shopmainslide} alt="" />
              </div>
            </Slider>
          </div>
        </div>
        <div className="content-main">
          <div className="home-product-list">
            <FeaturedProducts
              {...this.props}
              sateValChange={this.sateValChange}
              stayhomeblk={this.state.stayhomeblk}
            />
          </div>
        </div>
        <div className="promo-banner">
          <div className="container">
            <Slider {...settingspromoGallery}>
              <div>
                {" "}
                <img src={shopslide} alt="" />
              </div>
              <div>
                {" "}
                <img src={shopslideone} alt="" />
              </div>
              <div>
                {" "}
                <img src={shopslide} alt="" />
              </div>
            </Slider>
          </div>
        </div>
        <div className="welcome-banner">
          <div className="container">
            <div className="welcome-lhs">
              <h3>Welcome to Cedele</h3>
              <p>
                Cedele (“suh-deli”) is an artisanal food & beverage chain
                featuring full-service bakeries, cafés and restaurants offering
                a wide selection of soups, salads, breads, cakes, sandwiches and
                more. Cedele was founded with a passion for cooking and baking
                using fresh, natural ingredients, becoming a pioneer in
                Singapore for quick, casual dining concepts packed with the
                goodness of bold and authentic flavours since 1997.{" "}
              </p>
              <p>
                What we do is simple, but far from the ordinary, as we captivate
                Singaporean’s hearts through our honest to goodness food that
                nourishes the body and enables you to eat well, and be well.
              </p>
              <ul>
                <li>
                  {" "}
                  <img src={highlight} alt="" />
                </li>
                <li>
                  {" "}
                  <img src={highlight1} alt="" />
                </li>
                <li>
                  {" "}
                  <img src={highlight2} alt="" />
                </li>
                <li>
                  {" "}
                  <img src={highlight3} alt="" />
                </li>
              </ul>
            </div>
            <div className="welcome-rhs">
              <Slider {...settingswelcomGallery}>
                <div>
                  {" "}
                  <img src={welcomeslide} alt="" />
                </div>
                <div>
                  {" "}
                  <img src={welcomeslide1} alt="" />
                </div>
                <div>
                  {" "}
                  <img src={welcomeslide2} alt="" />
                </div>
                <div>
                  {" "}
                  <img src={welcomeslide3} alt="" />
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="cms-page">
          <div className="container-one cms-content">
            <div className="container cms-content">{this.state.pagedetail}</div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
