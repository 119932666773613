/* Live */
export const appId = "DB72C391-60BA-4176-8AB0-5CA0FEBC7BD1";
export const projectTitle = "Cedele";
export const apiUrl = "https://jupiter.promobuddy.asia/api/";
export const apiUrlV2 = "https://jupiter.promobuddy.asia/apiv2/";
export const apiUrlPro = "https://jupiter.promobuddy.asia/ninjapro/";
export const apiUrlNotify = "https://jupiter.promobuddy.asia/Pushorder/";
export const baseUrl = "https://cedele.promobuddy.asia/";
//export const baseUrl = "https://christmas2021.cedelemarket.com/";
//export const baseUrl = "https://www.cedele.com/";
//export const baseUrl = "http://localhost:3001/";

export const timThumpUrl = "https://jupiter.promobuddy.asia/timthumb.php?src=";
export const mediaUrl = "https://jupiter.promobuddy.asia/media/dev_team/";
export const blogImageUrl = "https://jupiter.promobuddy.asia/media/dev_team/blog/";
export const tagImageUrl = "https://jupiter.promobuddy.asia/media/dev_team/tag/";
export const proFolderUrl =
  "https://jupiter.promobuddy.asia/media/dev_team/products/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Cedele";

export const fbAppId = "114848765851933";
export const googleAppId =
  "698555529901-d4tbquslgjct8pueva481t3evltc4crr.apps.googleusercontent.com";

export const stripeKey = "pk_test_mSKQhHZYbRvm1YvuX9mKN4zx";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "cedele";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const currencySymbol = "$";
export const defaultoutlet = "160";
