/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfig(props) {
  const { email, password, rememberme } = props.fields;

  return {
    fields: ["email", "password"],

    validations: {
      email: [[isEmpty, email]],
      password: [[isEmpty, password]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
  }
  opentMemberLogin() {
    this.props.sateValChange("open-member-login", "Yes");
  }
  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgEmail, errMsgPassword;

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div
            className={fields.email !== "" ? "focus-out focused" : "focus-out"}
          >
            <label>Mobile Number</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            {errMsgEmail}
          </div>
        </div>
        <div className="form-group">
          <div
            className={
              fields.password !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>Password</label>

            <input
              type="password"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgPassword}
          </div>
        </div>
        <div className="form-group">
          <div className="custom_checkbox custom_checkbox_content">
            {fields.rememberme}
            <input
              type="checkbox"
              id="rememberme"
              value="1"
              checked={fields.rememberme}
              {...$field(
                "rememberme",
                (e) => onChange("rememberme", e.target.value),
                null,
                false
              )}
            />{" "}
            <span></span>
            <div className="sign_reg">
              <p>Remember Me</p>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="btn btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="controls two-links">
            <a href="#forgot-password-popup" className="open-popup-link">
              Forgot Password
            </a>{" "}
          </div>
          <br />
          <p>
            For Cedele Reward$ Members, click{" "}
            <a
              href="#membership-form-popup"
              data-effect="mfp-zoom-in"
              className="open-onmore-popup underline-cls"
            >
              here
            </a>{" "}
            to log in to your membership dashboard.
          </p>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
