/* eslint-disable */
import { push } from "react-router-redux";
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_CART_DETAIL,
  SET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  SET_UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
} from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
} from "../components/Helpers/Config";
import { getReferenceID } from "../components/Helpers/SettingHelper";
import Axios from "axios";
import cookie from "react-cookies";
import { setMinutes, setHours, format, parse } from "date-fns";
var qs = require("qs");

export const watchGetCartDetail = function* () {
  yield takeEvery(GET_CART_DETAIL, workerGetCartDetail);
};

export const watchUpdateCartDetail = function* () {
  yield takeEvery(UPDATE_CART_DETAIL, workerUpdateCartDetail);
};

export const watchDeleteCartDetail = function* () {
  yield takeEvery(DELETE_CART_DETAIL, workerDeleteCartDetail);
};

export const watchDestroyCartDetail = function* () {
  yield takeEvery(DESTROY_CART_DETAIL, workerDestroyCartDetail);
};

function* workerGetCartDetail(datas) {
  try {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    //customerParam += "&availability_id=" + cookie.load("defaultAvilablityId");
    if (
      cookie.load("orderZoneId") !== "" &&
      typeof cookie.load("orderZoneId") !== "undefined" &&
      typeof cookie.load("orderZoneId") !== undefined
    ) {
      customerParam += "&zone_id=" + cookie.load("orderZoneId");
    }

    if (
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== "undefined" &&
      typeof cookie.load("defaultAvilablityId") !== undefined
    ) {
      customerParam +=
        "&availability_id_check=" + cookie.load("defaultAvilablityId");
    }
    if (
      datas.changeDate !== "" &&
      typeof datas.changeDate !== undefined &&
      typeof datas.changeDate !== "undefined"
    ) {
      var sltdOrdDate = datas.changeDate;
      var sltdOrdTime = datas.changeTime;
      var OrdDate = format(sltdOrdDate, "yyyy-MM-dd");
      var OrdHours = sltdOrdTime.getHours();
      var OrdMunts = sltdOrdTime.getMinutes();
      var OrdSecnd = sltdOrdTime.getSeconds();
      var ordDateTime = new Date(OrdDate);
      ordDateTime.setHours(OrdHours);
      ordDateTime.setMinutes(OrdMunts);
      ordDateTime.setSeconds(OrdSecnd);
      console.log(ordDateTime, "ordDateTimeordDateTimeordDateTime");
      customerParam +=
        "&order_Date_Time=" + format(ordDateTime, "yyyy-MM-dd HH:mm:ss");
    } else if (
      cookie.load("orderDateTime") !== "" &&
      typeof cookie.load("orderDateTime") !== "undefined" &&
      typeof cookie.load("orderDateTime") !== undefined
    ) {
      customerParam += "&orderDateTime=" + cookie.load("orderDateTime");
    }

    if (
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== "undefined" &&
      typeof cookie.load("orderOutletId") !== undefined
    ) {
      customerParam += "&outlet_id=" + cookie.load("orderOutletId");
    }

    var deliveryDate = "";
    var deliveryTime =
      cookie.load("deliveryTime") !== undefined
        ? cookie.load("deliveryTime")
        : "";
    var deliveryDateTxt =
      cookie.load("deliveryDate") !== undefined
        ? cookie.load("deliveryDate")
        : "";
    if (deliveryDateTxt != "") {
      var deliveryDateArr = deliveryDateTxt.split("/");
      deliveryDate =
        deliveryDateArr[2] +
        "-" +
        deliveryDateArr[1] +
        "-" +
        deliveryDateArr[0];
    }

    const uri =
      apiUrl +
      "cart/contents?status=A&app_id=" +
      appId +
      customerParam +
      "&orderdate=" +
      deliveryDate +
      "&ordertime=" +
      deliveryTime +
      "&stockvalide=yes";
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CART_DETAIL, value: resultArr });
  } catch {
    console.log("Get Cart Detail Failed");
  }
}

function* workerUpdateCartDetail({
  productId,
  cartItemId,
  cartQty,
  orderVoucherId,
}) {
  try {
    var postObject = {};
    postObject = {
      app_id: appId,
      cart_item_id: cartItemId,
      product_id: productId,
      product_qty: cartQty,
      cartAction: "update",
      voucher_order_id: orderVoucherId,
    };

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getReferenceID();
    } else {
      postObject["customer_id"] = cookie.load("UserId");
    }

    const uri = apiUrl + "cart/update";
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_UPDATE_CART_DETAIL, value: resultArr });
    yield put({ type: GET_CART_DETAIL });
  } catch {
    console.log("Get Cart Detail Failed");
  }
}

function* workerDeleteCartDetail({ cartItemId }) {
  try {
    var postObject = {};
    postObject = {
      app_id: appId,
      cart_item_id: cartItemId,
      cartAction: "Delete",
    };

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getReferenceID();
    } else {
      postObject["customer_id"] = cookie.load("UserId");
    }

    const uri = apiUrl + "cart/delete";
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
    yield put({ type: GET_CART_DETAIL });
  } catch {
    console.log("Get Cart Detail Failed");
  }
}

function* workerDestroyCartDetail() {
  try {
    var postObject = {};
    postObject = { app_id: appId };

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getReferenceID();
    } else {
      postObject["customer_id"] = cookie.load("UserId");
    }

    const uri = apiUrl + "cart/destroy";
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
    yield put({ type: GET_CART_DETAIL });
  } catch {
    console.log("Get Cart Detail Failed");
  }
}
