/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA } from "../../actions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import innerbanner from "../../common/images/inner-banner.jpg";
class Pages extends Component {
  constructor(props) {
    super(props);
    if (
      this.props.match.params !== "" &&
      this.props.match.params.page_slug !== undefined
    ) {
      var page_slug = this.props.match.params.page_slug;
    } else {
      var page_slug = this.props.match.url.replace(/\\|\//g, "");
    }

    this.state = {
      pagedata: [],
      pagedetail: "",
      gallery_images: [],
      pagetitle_txt: "",
      page_slug: page_slug,
      meta_description: "",
      meta_keyword: "",
      meta_title: "",
    };

    this.props.getSettings();
    this.props.getRequestpage(page_slug);
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.page_slug !== this.props.match.params.page_slug
    ) {
      if ($(".trigger_menu").length > 0) {
        $(".trigger_menu").toggleClass("active");
        if ($(".hmenu_list").hasClass("open")) {
          $(".mega_menu").slideUp();
        }
        $(".hmenu_list").toggleClass("open");
      }
      var pageslug = "";
      if (
        nextProps.match.params !== "" &&
        nextProps.match.params.page_slug !== undefined
      ) {
        pageslug = nextProps.match.params.page_slug;
      } else {
        pageslug = nextProps.match.url.replace(/\\|\//g, "");
      }
      //var pageslug = nextProps.match.path.replace(/\\|\//g, "");
      this.props.getRequestpage(pageslug);
    }

    if (nextProps.pagedata !== this.state.pagedata) {
      var pageDetails = "";
      var pageTitleTxt = "";
      var gallery_images = [];
      var meta_description = "";
      var meta_keyword = "";
      var meta_title = "";
      if (Object.keys(nextProps.pagedata).length > 0) {
        var pagedataTxt = nextProps.pagedata[0].cmspage_description;
        pageTitleTxt = nextProps.pagedata[0].cmspage_title;
        pageDetails = pagedataTxt !== "" ? Parser(pagedataTxt) : "";
        gallery_images = nextProps.pagedata[0].gallery_images;
        meta_description = nextProps.pagedata[0].cmspage_meta_description;
        meta_keyword = nextProps.pagedata[0].cmspage_meta_keyword;
        meta_title = nextProps.pagedata[0].cmspage_meta_title;
      }

      this.setState(
        {
          pagedata: nextProps.pagedata,
          pagedetail: pageDetails,
          pagetitle_txt: pageTitleTxt,
          gallery_images: gallery_images,
          meta_description: meta_description,
          meta_keyword: meta_keyword,
          meta_title: meta_title,
        },
        function () {
          $(".dvLoadrCls").fadeOut(500);
        }
      );
    }
  }

  render() {
    return (
      <div className="pagesList-main-div">
        <Helmet>
          {this.state.meta_title && (
            <meta name="title" content={this.state.meta_title} />
          )}
          {this.state.meta_keyword && (
            <meta name="keyword" content={this.state.meta_keyword} />
          )}
          {this.state.meta_description && (
            <meta name="description" content={this.state.meta_description} />
          )}
        </Helmet>
        {/* Header start */}
        <Header />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            {this.props.common !== "" &&
            this.state.gallery_images.length > 0 ? (
              <img
                src={
                  this.props.common.gallery_image_path +
                  "/" +
                  this.state.gallery_images[0]
                }
              />
            ) : (
              <img src={innerbanner} />
            )}
            <p>{this.state.pagetitle_txt}</p>
          </div>
        </div>
        <div
          className={
            this.state.page_slug === "our-story"
              ? "cms-page story-control"
              : this.state.page_slug === "rewards"
              ? "cms-page rewards-cms"
              : "cms-page"
          }
        >
          <div className="container-one cms-content">
            <div className="container cms-content">{this.state.pagedetail}</div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var pagedataRst = Array();
  var common = [];
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
      common = state.pagedata[0].common;
    }
  }

  return {
    globalsettings: state.settings,
    pagedata: pagedataRst,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },
  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
