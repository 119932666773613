/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MENU_NAVIGATION,
  SET_MENU_NAVIGATION,
  GET_PRODUCT_LIST,
  SET_PRODUCT,
} from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  defaultoutlet,
} from "../components/Helpers/Config";
import { padset } from "../components/Helpers/SettingHelper";

import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMenuNavigation = function* () {
  yield takeEvery(GET_MENU_NAVIGATION, workerGetMenuNavigation);
};

export const watchGetProducts = function* () {
  yield takeEvery(GET_PRODUCT_LIST, workerGetProducts);
};

function* workerGetMenuNavigation() {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? defaultoutlet
        : cookie.load("orderOutletId");
    var outletcon = "";
    if (orderOutletId !== "") {
      outletcon = "&outletId=" + orderOutletId;
    }
    var deliveryDate = "";
    var deliveryTime =
      cookie.load("deliveryTime") !== undefined
        ? cookie.load("deliveryTime")
        : "";
    var deliveryDateTxt =
      cookie.load("deliveryDate") !== undefined
        ? cookie.load("deliveryDate")
        : "";
    if (deliveryDateTxt != "") {
      var deliveryDateArr = deliveryDateTxt.split("/");
      deliveryDate =
        deliveryDateArr[2] +
        "-" +
        deliveryDateArr[1] +
        "-" +
        deliveryDateArr[0];
    }

    const uri =
      apiUrlV2 +
      "products/getMenuNavigation?app_id=" +
      appId +
      /*  '&availability=' +
      availabilityId +
      outletcon + */
      "&orderdate=" +
      deliveryDate +
      "&ordertime=" +
      deliveryTime +
      "&source_type=ecommerce";
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    if (result.data.status == "ok") {
      cookie.save("firstNavigation", result.data.result_set[0].pro_cate_slug, {
        path: "/",
      });
    }
    yield put({ type: SET_MENU_NAVIGATION, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}

function* workerGetProducts({ catslug, subcatslug }) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? ""
        : cookie.load("orderOutletId");
    var outletcon = "";
    if (orderOutletId !== "") {
      outletcon = "&outletId=" + orderOutletId;
    }
    var fav_cust_id =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== "undefined" &&
      typeof cookie.load("UserId") !== undefined
        ? "&fav_cust_id=" + cookie.load("UserId")
        : "";
    var deliveryDate = "";
    var deliveryTime =
      cookie.load("deliveryTime") !== undefined
        ? cookie.load("deliveryTime")
        : "";
    var deliveryDateTxt =
      cookie.load("deliveryDate") !== undefined
        ? cookie.load("deliveryDate")
        : "";
    if (deliveryDateTxt != "") {
      var deliveryDateArr = deliveryDateTxt.split("/");
      deliveryDate =
        deliveryDateArr[2] +
        "-" +
        deliveryDateArr[1] +
        "-" +
        deliveryDateArr[0];
    }

    if (deliveryDate == "") {
      var todayDate = new Date();
      deliveryDate =
        todayDate.getFullYear() +
        "-" +
        padset(parseInt(todayDate.getMonth()) + 1) +
        "-" +
        padset(todayDate.getDate());
      deliveryTime =
        padset(todayDate.getHours()) +
        ":" +
        padset(todayDate.getMinutes()) +
        ":00";
    }

    const uri =
      apiUrlV2 +
      "products/getAllProducts?app_id=" +
      appId +
      /*  "&availability=" +
      availabilityId + */
      "&category_slug=" +
      catslug +
      "&subcate_slug=" +
      subcatslug +
      /* outletcon + */
      fav_cust_id +
      "&orderdate=" +
      deliveryDate +
      "&ordertime=" +
      deliveryTime +
      "&source_type=ecommerce&specialprice_applicable=orderdate&order_datetxt=" +
      deliveryDate;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}
